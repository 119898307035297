import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import _ from 'lodash';
import MapPopUpDiv from '../../Components/Popup/MapPopup';
import config from "../../config";
import ResultsContainer from '../../Components/Popup/ResultContainer';

export function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export async function updateStats(start,stop,mode,state,county,mapData,that,from){
    let test=mode;
    let previousCases;
    let prevStart;
    let prevStop;
    var theQ="";
    var theQSingle="";
    var theEffect={};

    if(test==="Country"){
        that.setState({
            mode:test,
            stateSelected:"",
            countySelected:""
        });
        //console.log("MODE="+mode)
        theQ="enddate_converted='" + moment(mapData.timeSlider.timeExtent.end.getTime()).format('l')+"'";
        // below is the orginal from koopdev
        //theQ="endDate_Converted<=" + moment(mapData.timeSlider.timeExtent.end);
        
        // NOTE
        // the below was commented out to change state query to group by
        // theQSingle="flag=1 AND date='" + moment(mapData.timeSlider.timeExtent.end.getTime()).format('YYYY-MM-DD')+"'";
        theQSingle="date='" + moment(mapData.timeSlider.timeExtent.end.getTime()).format('YYYY-MM-DD')+"'";
        theEffect={
            filter: {
                where:theQSingle,
                geometry: mapData.view.extent
            },
            excludedEffect: config.layers.cases.excludeEffects,
            includedEffect: config.layers.cases.includedEffects
        };
    }else if(test==="State"){
        //make sure state is not empty
        // console.log("state="+state);
        that.setState({
            mode:test,
            stateSelected:state
        });
        if(state!==null){
            theQ="st_abbrev='"+state+"' AND enddate_converted='" + moment(mapData.timeSlider.timeExtent.end.getTime()).format('l')+"'";
            //below is the og koopDev
            // theQ="st_abbrev='"+state+"' AND enddate_converted<=DATE'" + moment(mapData.timeSlider.timeExtent.end)+'';
            theQSingle="st_abbrev='"+state+"' AND flag=1 AND date='" + moment(mapData.timeSlider.timeExtent.end.getTime()).format('YYYY-MM-DD')+"'";
            // theQSingle="st_abbrev='"+state+"' AND startdate_converted_converted='" + moment(mapData.timeSlider.timeExtent.end.getTime()).format('YYYYMMDDhhmmss')+"'";
            theEffect={
                filter: {
                    where:theQSingle,
                    geometry: mapData.view.extent,
                    // timeExtent: mapData.timeSlider.timeExtent
                },
                excludedEffect: config.layers.cases.excludeEffects,
                includedEffect: config.layers.cases.includedEffects
            };
            
        }

    }else if(test==="County"){
        county=localStorage.getItem('countySelected');
        if(county!==null){
            // theQ="fipscovid='"+county+"' AND endDate_Converted<='" + moment(timeSlider.timeExtent.end.getTime()).format('l')+"'";
            theQ="fipscovid='"+county+"' AND enddate_converted='" + moment(mapData.timeSlider.timeExtent.end.getTime()).format('l')+"'";
            theQSingle="fipscovid='"+county+"' AND flag=1 AND date='" + moment(mapData.timeSlider.timeExtent.end.getTime()).format('YYYY-MM-DD')+"'";
            theEffect={
                filter: {
                    where:theQSingle,
                    // geometry: mapData.view.extent,
                },
                excludedEffect: config.layers.cases.excludeEffects,
                includedEffect: config.layers.cases.includedEffects
            };
        }
        
    }else{
    }

        mapData.layerCases.definitionExpression=theQ;
        mapData.layerView.effect = theEffect;

        const statQuery = mapData.layerView.effect.filter.createQuery();
        statQuery.where=theQSingle;
        statQuery.groupByFieldsForStatistics=["groupstatecounty"];
        statQuery.outStatistics = [
            // caseMin,
            // caseAvg,
            // caseCount,
            // newcasesCount,
            // deathCount,
            // newDeathCount,
            // pop19Sum,
            // per100kSum,
            // new shit below
            caseMax,
            newcasesMax,
            newcasesMin,
            deathMax,
            pop19Max,
            newDeathMax,
            per100kMax,
            per100kMin,
            maxObjectID,
            maxfips
            
        ];
        let result = await mapData.layerCases.queryFeatures(statQuery);
        let deathPColor="";
        let per100kTotalnew=0;
        let deathChangePercentNew=0;
        let casePercentChangeNew=0;
        let deathPercentNew=0;
        let totalCases=0;
        let totalNewCases=0;
        let totalDeaths=0;
        let totalNewDeaths=0;
        let totalPop=0;
        let maxPer100kNew;
        let minPer100kNew;
        let maxNewCases;
        let minNewCases;


        if(_.get(result,"features.length",0)>0){
          // NEW STUFF
          // with group by need to loop over all features
          
          totalCases = _.sumBy(result.features, 'attributes.Max_cases');
          totalNewCases = _.sumBy(result.features,'attributes.Max_newcases');
          totalDeaths = _.sumBy(result.features, 'attributes.maxDeaths');
          totalNewDeaths = _.sumBy(result.features,'attributes.newDeathMax');
          totalPop = _.sumBy(result.features, 'attributes.pop19Max');
          maxPer100kNew = _.maxBy(result.features,'attributes.Max_per100k');
          minPer100kNew = _.minBy(result.features,'attributes.Min_per100k');
          maxNewCases = _.maxBy(result.features,'attributes.Max_newcases');
          minNewCases = _.minBy(result.features,'attributes.Max_newcases');
          maxPer100kNew=maxPer100kNew.attributes.Max_per100k;
          minPer100kNew=minPer100kNew.attributes.Min_per100k;
          maxNewCases=maxNewCases.attributes.Max_newcases;
          minNewCases=minNewCases.attributes.Max_newcases;

          if(totalCases>0){
            per100kTotalnew = ((totalCases/totalPop)*100000).toFixed(2);
            casePercentChangeNew = ((totalNewCases/(totalCases-totalNewCases))*100).toFixed(2);
            deathPercentNew = ((totalDeaths/totalCases)*100).toFixed(2);
            // casePercentChange=(attributes.newcasesCount/(casesShow-attributes.newcasesCount))*100;
          }

          if(totalDeaths>0){
            deathChangePercentNew = ((totalNewDeaths/(totalDeaths-totalNewDeaths))*100).toFixed(2);
            
            // deathChangePercentNew=(attributes.newDeathCount/(deathShow-attributes.newDeathCount))*100;
            // deathChangePercent="";
          }

          if(test==="County"){
            mapData.layerCases.renderer = {
                // type: "simple",
                // field: "cases",
                // symbol: {
                //     type: "simple-marker",
                //     size: 6,
                //     color: [255, 20, 20, 0.8],
                //     outline: {
                //         color: "rgba(255, 255, 255, 0.95)",
                //         width: 0.55
                //     }
                // },
                type: "simple",
                symbol: {
                  type: "cim",
                  data: {
                    type: "CIMSymbolReference",
                    symbol: {
                      type: "CIMPointSymbol",
                      symbolLayers: [
                        {
                          type: "CIMVectorMarker",
                          enable: true,
                          anchorPoint: { x: 0, y: 0 },
                          anchorPointUnits: "Relative",
                          primitiveName: "innerSizeOverride",
                          frame: { xmin: 0.0, ymin: 0.0, xmax: 17.0, ymax: 17.0 },
                          markerGraphics: [
                            {
                              type: "CIMMarkerGraphic",
                              geometry: {
                                rings: [
                                  [
                                    [8.5, 0.2],
                                    [7.06, 0.33],
                                    [5.66, 0.7],
                                    [4.35, 1.31],
                                    [3.16, 2.14],
                                    [2.14, 3.16],
                                    [1.31, 4.35],
                                    [0.7, 5.66],
                                    [0.33, 7.06],
                                    [0.2, 8.5],
                                    [0.33, 9.94],
                                    [0.7, 11.34],
                                    [1.31, 12.65],
                                    [2.14, 13.84],
                                    [3.16, 14.86],
                                    [4.35, 15.69],
                                    [5.66, 16.3],
                                    [7.06, 16.67],
                                    [8.5, 16.8],
                                    [9.94, 16.67],
                                    [11.34, 16.3],
                                    [12.65, 15.69],
                                    [13.84, 14.86],
                                    [14.86, 13.84],
                                    [15.69, 12.65],
                                    [16.3, 11.34],
                                    [16.67, 9.94],
                                    [16.8, 8.5],
                                    [16.67, 7.06],
                                    [16.3, 5.66],
                                    [15.69, 4.35],
                                    [14.86, 3.16],
                                    [13.84, 2.14],
                                    [12.65, 1.31],
                                    [11.34, 0.7],
                                    [9.94, 0.33],
                                    [8.5, 0.2]
                                  ]
                                ]
                              },
                              symbol: {
                                type: "CIMPolygonSymbol",
                                symbolLayers: [
                                  {
                                    type: "CIMSolidFill",
                                    enable: true,
                                    color: [0, 0, 0, 30]
                                  }
                                ]
                              }
                            }
                          ],
                          scaleSymbolsProportionally: true,
                          respectFrame: true
                        },
                        {
                          type: "CIMVectorMarker",
                          enable: true,
                          anchorPoint: { x: 0, y: 0 },
                          anchorPointUnits: "Relative",
                          primitiveName: "outerSizeOverride",
                          frame: { xmin: 0.0, ymin: 0.0, xmax: 17.0, ymax: 17.0 },
                          markerGraphics: [
                            {
                              type: "CIMMarkerGraphic",
                              geometry: {
                                rings: [
                                  [
                                    [8.5, 0.2],
                                    [7.06, 0.33],
                                    [5.66, 0.7],
                                    [4.35, 1.31],
                                    [3.16, 2.14],
                                    [2.14, 3.16],
                                    [1.31, 4.35],
                                    [0.7, 5.66],
                                    [0.33, 7.06],
                                    [0.2, 8.5],
                                    [0.33, 9.94],
                                    [0.7, 11.34],
                                    [1.31, 12.65],
                                    [2.14, 13.84],
                                    [3.16, 14.86],
                                    [4.35, 15.69],
                                    [5.66, 16.3],
                                    [7.06, 16.67],
                                    [8.5, 16.8],
                                    [9.94, 16.67],
                                    [11.34, 16.3],
                                    [12.65, 15.69],
                                    [13.84, 14.86],
                                    [14.86, 13.84],
                                    [15.69, 12.65],
                                    [16.3, 11.34],
                                    [16.67, 9.94],
                                    [16.8, 8.5],
                                    [16.67, 7.06],
                                    [16.3, 5.66],
                                    [15.69, 4.35],
                                    [14.86, 3.16],
                                    [13.84, 2.14],
                                    [12.65, 1.31],
                                    [11.34, 0.7],
                                    [9.94, 0.33],
                                    [8.5, 0.2]
                                  ]
                                ]
                              },
                              symbol: {
                                type: "CIMLineSymbol",
                                symbolLayers: [
                                  {
                                    type: "CIMSolidStroke",
                                    enable: true,
                                    color: [150, 150, 150, 100],
                                    width: 1
                                  }
                                ]
                              }
                            }
                          ],
                          scaleSymbolsProportionally: true,
                          respectFrame: true
                        }
                      ]
                    },
                    primitiveOverrides: [
                      {
                        type: "CIMPrimitiveOverride",
                        primitiveName: "outerSizeOverride",
                        propertyName: "Size",
                        valueExpressionInfo: {
                          type: "CIMExpressionInfo",
                          title: "Size in pixels of outer ring at maxScale",
                          // the pixel size at the largest scale
                          // 120 represents the pixel size of the
                          // circles at the view's largest scale (1:2,311,161)
                          expression: "70 * 577790 / $view.scale",
                          returnType: "Default"
                        }
                      },
                      {
                        type: "CIMPrimitiveOverride",
                        primitiveName: "innerSizeOverride",
                        propertyName: "Size",
                        valueExpressionInfo: {
                          type: "CIMExpressionInfo",
                          title: "Size in pixels of inner ring at maxScale",
                          // outerSize is the pixel size at the largest scale
                          // The innerSize is determined by multiplying
                          // the outerSize by the forest ratio
                          // var casesRatio = $feature.cases/`+casesShow+`;
                          // casesShow
                          // var casesRatio = (($feature.cases - previousDay)/previousDay);
                          //   Console('innerSize=' + innerSize + ', casesRatio= ' + casesRatio + ', maxCases='+maxCases +' cases='+$feature.cases + ' minCases='+minCases  );
                          // Console('innerSize=' + innerSize + ', per100kRatio= ' + casesRatio + ', maxper100k='+maxCases +' per100k='+$feature.per100k + ' minper100k='+minCases  );  
                          // per100kMax=attributes.Max_per100k;
                            // per100kMin=attributes.Min_per100k;
                            // per100kSum=attributes.per100kSum;
                          expression: `
                            var maxCases = `+per100kMax+`;
                            var minCases = `+per100kMin+`;
                            var maxScale=68;
                            var minScale=5;
                            var casesRatio = ((maxScale - minScale) * ($feature.per100k - minCases) / (maxCases - minCases) + minScale);
                            
                            casesRatio = Round(casesRatio,1)/100;
                            var outerSize = 70 * 577790 / $view.scale;
                            var innerSize = outerSize * casesRatio;
                            innerSize = IIF( casesRatio > 1, 68 * 577790 / $view.scale, innerSize );
                            
                            return IIF( innerSize < 3, 5, Round(innerSize,1) );
                            
                          `,
                          returnType: "Default"
                        //   return IIF( innerSize < .4, 3, innerSize );
                        }
                      }
                    ]
                  }
                },
                visualVariables: [
                  {
                    type: "color",
                    field: "cases",
                    stops: [
                      {
                        value: 153,
                        color: "#31778E"
                      },
                      {
                        value: 413,
                        color: "#D0B285"
                      },
                      {
                        value: 797,
                        color: "#985914"
                      },
                      {
                        value: 1352,
                        color: "#533C19"
                      },
                      {
                        value: 2117,
                        color: "#F58C33"
                      },
                      {
                          value: 3490,
                          color: "#F9AC3D"
                      },
                      {
                        value: 3734,
                        color: "#CC643C"
                    },
                    {
                        value: 15000,
                        color: "#CF4917"
                    }
                    ]
                  }
                ]
                
            };

          }else{
              mapData.layerCases.renderer = {
                  
                  type: "simple",
                  symbol: {
                    type: "cim",
                    data: {
                      type: "CIMSymbolReference",
                      symbol: {
                        type: "CIMPointSymbol",
                        symbolLayers: [
                          {
                            type: "CIMVectorMarker",
                            enable: true,
                            anchorPoint: { x: 0, y: 0 },
                            anchorPointUnits: "Relative",
                            primitiveName: "innerSizeOverride",
                            frame: { xmin: 0.0, ymin: 0.0, xmax: 17.0, ymax: 17.0 },
                            markerGraphics: [
                              {
                                type: "CIMMarkerGraphic",
                                geometry: {
                                  rings: [
                                    [
                                      [8.5, 0.2],
                                      [7.06, 0.33],
                                      [5.66, 0.7],
                                      [4.35, 1.31],
                                      [3.16, 2.14],
                                      [2.14, 3.16],
                                      [1.31, 4.35],
                                      [0.7, 5.66],
                                      [0.33, 7.06],
                                      [0.2, 8.5],
                                      [0.33, 9.94],
                                      [0.7, 11.34],
                                      [1.31, 12.65],
                                      [2.14, 13.84],
                                      [3.16, 14.86],
                                      [4.35, 15.69],
                                      [5.66, 16.3],
                                      [7.06, 16.67],
                                      [8.5, 16.8],
                                      [9.94, 16.67],
                                      [11.34, 16.3],
                                      [12.65, 15.69],
                                      [13.84, 14.86],
                                      [14.86, 13.84],
                                      [15.69, 12.65],
                                      [16.3, 11.34],
                                      [16.67, 9.94],
                                      [16.8, 8.5],
                                      [16.67, 7.06],
                                      [16.3, 5.66],
                                      [15.69, 4.35],
                                      [14.86, 3.16],
                                      [13.84, 2.14],
                                      [12.65, 1.31],
                                      [11.34, 0.7],
                                      [9.94, 0.33],
                                      [8.5, 0.2]
                                    ]
                                  ]
                                },
                                symbol: {
                                  type: "CIMPolygonSymbol",
                                  symbolLayers: [
                                    {
                                      type: "CIMSolidFill",
                                      enable: true,
                                      color: [0, 0, 0, 30]
                                    }
                                  ]
                                }
                              }
                            ],
                            scaleSymbolsProportionally: true,
                            respectFrame: true
                          },
                          {
                            type: "CIMVectorMarker",
                            enable: true,
                            anchorPoint: { x: 0, y: 0 },
                            anchorPointUnits: "Relative",
                            primitiveName: "outerSizeOverride",
                            frame: { xmin: 0.0, ymin: 0.0, xmax: 17.0, ymax: 17.0 },
                            markerGraphics: [
                              {
                                type: "CIMMarkerGraphic",
                                geometry: {
                                  rings: [
                                    [
                                      [8.5, 0.2],
                                      [7.06, 0.33],
                                      [5.66, 0.7],
                                      [4.35, 1.31],
                                      [3.16, 2.14],
                                      [2.14, 3.16],
                                      [1.31, 4.35],
                                      [0.7, 5.66],
                                      [0.33, 7.06],
                                      [0.2, 8.5],
                                      [0.33, 9.94],
                                      [0.7, 11.34],
                                      [1.31, 12.65],
                                      [2.14, 13.84],
                                      [3.16, 14.86],
                                      [4.35, 15.69],
                                      [5.66, 16.3],
                                      [7.06, 16.67],
                                      [8.5, 16.8],
                                      [9.94, 16.67],
                                      [11.34, 16.3],
                                      [12.65, 15.69],
                                      [13.84, 14.86],
                                      [14.86, 13.84],
                                      [15.69, 12.65],
                                      [16.3, 11.34],
                                      [16.67, 9.94],
                                      [16.8, 8.5],
                                      [16.67, 7.06],
                                      [16.3, 5.66],
                                      [15.69, 4.35],
                                      [14.86, 3.16],
                                      [13.84, 2.14],
                                      [12.65, 1.31],
                                      [11.34, 0.7],
                                      [9.94, 0.33],
                                      [8.5, 0.2]
                                    ]
                                  ]
                                },
                                symbol: {
                                  type: "CIMLineSymbol",
                                  symbolLayers: [
                                    {
                                      type: "CIMSolidStroke",
                                      enable: true,
                                      color: [150, 150, 150, 100],
                                      width: 1
                                    }
                                  ]
                                }
                              }
                            ],
                            scaleSymbolsProportionally: true,
                            respectFrame: true
                          }
                        ]
                      },
                      primitiveOverrides: [
                        {
                          type: "CIMPrimitiveOverride",
                          primitiveName: "outerSizeOverride",
                          propertyName: "Size",
                          valueExpressionInfo: {
                            type: "CIMExpressionInfo",
                            title: "Size in pixels of outer ring at maxScale",
                            // the pixel size at the largest scale
                            // 120 represents the pixel size of the
                            // circles at the view's largest scale (1:2,311,161)
                            expression: "70 * 577790 / $view.scale",
                            returnType: "Default"
                          }
                        },
                        {
                          type: "CIMPrimitiveOverride",
                          primitiveName: "innerSizeOverride",
                          propertyName: "Size",
                          valueExpressionInfo: {
                            type: "CIMExpressionInfo",
                            title: "Size in pixels of inner ring at maxScale",
                            // Testing - Changing to max min new cases
                            // var maxCases = `+maxPer100kNew+`;
                            // var minCases = `+minPer100kNew+`;

                            expression: `
                              var maxCases = `+maxNewCases+`;
                              var minCases = `+minNewCases+`;
                              var maxScale=68;
                              var minScale=5;
                              var casesRatio = ((maxScale - minScale) * ($feature.newcases - minCases) / (maxCases - minCases) + minScale);
                              
                              casesRatio = Round(casesRatio,1)/100;
                              var outerSize = 70 * 577790 / $view.scale;
                              var innerSize = outerSize * casesRatio;
                              innerSize = IIF( casesRatio > 1, 68 * 577790 / $view.scale, innerSize );
                              
                              return IIF( innerSize < 3, 5, Round(innerSize,1) );
                              
                            `,
                            returnType: "Default"
                          //   return IIF( innerSize < .4, 3, innerSize );
                          }
                        }
                      ]
                    }
                  },
                  visualVariables: [
                    {
                      type: "color",
                      field: "cases",
                      stops: [
                        {
                          value: 153,
                          color: "#31778E"
                        },
                        {
                          value: 413,
                          color: "#D0B285"
                        },
                        {
                          value: 797,
                          color: "#985914"
                        },
                        {
                          value: 1352,
                          color: "#533C19"
                        },
                        {
                          value: 2117,
                          color: "#F58C33"
                        },
                        {
                            value: 3490,
                            color: "#F9AC3D"
                        },
                        {
                          value: 3734,
                          color: "#CC643C"
                      },
                      {
                          value: 15000,
                          color: "#CF4917"
                      }
                      ]
                    }
                  ]
              };

          }


        }else{
          // no feautres
        }

        that.setState({
          records:result.features,
          dateRange:mapData.timeSlider.timeExtent.start.toLocaleDateString(),
          totalPer100K:per100kTotalnew,
          totalPop:abbreviate(totalPop,3,3,'M'),
          totalCases:totalCases,
          newcases:totalNewCases,
          newcasesP:casePercentChangeNew,
          newcasesColor:"",
          totalDeath:totalDeaths,
          totalDeathP:deathPercentNew,
          deathPChange:deathChangePercentNew,
          deathPColor:deathPColor,
          newDeath:totalNewDeaths,
          moveDate:mapData.timeSlider.timeExtent.start
      });

         
        // mapData.layerCases.queryFeatures(statQuery)
        //     .then(function(result) {
        //     // statsDiv.innerHTML="";
        //         if(result.error){
        //             return result.error;
        //         }else{
        //             // lodash 
        //             if(_.get(result,"features.length",0)>0){
        //               // NEW STUFF
        //               // with group by need to loop over all features
        //               // let deathPColor="";
        //               // let per100kTotalnew=0;
        //               // let deathChangePercentNew=0;
        //               // let casePercentChangeNew=0;
        //               // let deathPercentNew=0;
        //               // const totalCases = _.sumBy(result.features, 'attributes.Max_cases');
        //               // const totalNewCases = _.sumBy(result.features,'attributes.Max_newcases');
        //               // const totalDeaths = _.sumBy(result.features, 'attributes.maxDeaths');
        //               // const totalNewDeaths = _.sumBy(result.features,'attributes.newDeathMax');
        //               // const totalPop = _.sumBy(result.features, 'attributes.pop19Max');
        //               // let maxPer100kNew = _.maxBy(result.features,'attributes.Max_per100k');
        //               // let minPer100kNew = _.minBy(result.features,'attributes.Min_per100k');
        //               // let maxNewCases = _.maxBy(result.features,'attributes.Max_newcases');
        //               // let minNewCases = _.minBy(result.features,'attributes.Max_newcases');
        //               // maxPer100kNew=maxPer100kNew.attributes.Max_per100k;
        //               // minPer100kNew=minPer100kNew.attributes.Min_per100k;
        //               // maxNewCases=maxNewCases.attributes.Max_newcases;
        //               // minNewCases=minNewCases.attributes.Max_newcases;
        //               // _.maxBy(objects, 'n');
                      
        //               // Math
        //               // if(totalCases>0){
        //               //   per100kTotalnew = ((totalCases/totalPop)*100000).toFixed(2);
        //               //   casePercentChangeNew = ((totalNewCases/(totalCases-totalNewCases))*100).toFixed(2);
        //               //   deathPercentNew = ((totalDeaths/totalCases)*100).toFixed(2);
        //               //   // casePercentChange=(attributes.newcasesCount/(casesShow-attributes.newcasesCount))*100;
        //               // }

        //               // if(totalDeaths>0){
        //               //   deathChangePercentNew = ((totalNewDeaths/(totalDeaths-totalNewDeaths))*100).toFixed(2);
                        
        //               //   // deathChangePercentNew=(attributes.newDeathCount/(deathShow-attributes.newDeathCount))*100;
        //               //   // deathChangePercent="";
        //               // }
        //               // console.log("Max New Cases="+maxNewCases);
        //               // console.log("Min New Cases="+minNewCases);
        //               // console.log(maxPer100kNew);
        //               // console.log("Total Cases="+totalCases+" - Total New Cases="+totalNewCases+" - Percent Change="+casePercentChangeNew);
        //               // console.log("Max 100K="+maxPer100kNew);
        //               // console.log("Min 100K="+minPer100kNew);
        //               // console.log("Total Deaths="+totalDeaths+" - Total New Deaths="+totalNewDeaths);
        //               // console.log("Total Pop="+totalPop+" - Per 100K="+per100kTotalnew);
        //               // END MATH



        //                 // if(test==="County"){
        //                 //     mapData.layerCases.renderer = {
        //                 //         // type: "simple",
        //                 //         // field: "cases",
        //                 //         // symbol: {
        //                 //         //     type: "simple-marker",
        //                 //         //     size: 6,
        //                 //         //     color: [255, 20, 20, 0.8],
        //                 //         //     outline: {
        //                 //         //         color: "rgba(255, 255, 255, 0.95)",
        //                 //         //         width: 0.55
        //                 //         //     }
        //                 //         // },
        //                 //         type: "simple",
        //                 //         symbol: {
        //                 //           type: "cim",
        //                 //           data: {
        //                 //             type: "CIMSymbolReference",
        //                 //             symbol: {
        //                 //               type: "CIMPointSymbol",
        //                 //               symbolLayers: [
        //                 //                 {
        //                 //                   type: "CIMVectorMarker",
        //                 //                   enable: true,
        //                 //                   anchorPoint: { x: 0, y: 0 },
        //                 //                   anchorPointUnits: "Relative",
        //                 //                   primitiveName: "innerSizeOverride",
        //                 //                   frame: { xmin: 0.0, ymin: 0.0, xmax: 17.0, ymax: 17.0 },
        //                 //                   markerGraphics: [
        //                 //                     {
        //                 //                       type: "CIMMarkerGraphic",
        //                 //                       geometry: {
        //                 //                         rings: [
        //                 //                           [
        //                 //                             [8.5, 0.2],
        //                 //                             [7.06, 0.33],
        //                 //                             [5.66, 0.7],
        //                 //                             [4.35, 1.31],
        //                 //                             [3.16, 2.14],
        //                 //                             [2.14, 3.16],
        //                 //                             [1.31, 4.35],
        //                 //                             [0.7, 5.66],
        //                 //                             [0.33, 7.06],
        //                 //                             [0.2, 8.5],
        //                 //                             [0.33, 9.94],
        //                 //                             [0.7, 11.34],
        //                 //                             [1.31, 12.65],
        //                 //                             [2.14, 13.84],
        //                 //                             [3.16, 14.86],
        //                 //                             [4.35, 15.69],
        //                 //                             [5.66, 16.3],
        //                 //                             [7.06, 16.67],
        //                 //                             [8.5, 16.8],
        //                 //                             [9.94, 16.67],
        //                 //                             [11.34, 16.3],
        //                 //                             [12.65, 15.69],
        //                 //                             [13.84, 14.86],
        //                 //                             [14.86, 13.84],
        //                 //                             [15.69, 12.65],
        //                 //                             [16.3, 11.34],
        //                 //                             [16.67, 9.94],
        //                 //                             [16.8, 8.5],
        //                 //                             [16.67, 7.06],
        //                 //                             [16.3, 5.66],
        //                 //                             [15.69, 4.35],
        //                 //                             [14.86, 3.16],
        //                 //                             [13.84, 2.14],
        //                 //                             [12.65, 1.31],
        //                 //                             [11.34, 0.7],
        //                 //                             [9.94, 0.33],
        //                 //                             [8.5, 0.2]
        //                 //                           ]
        //                 //                         ]
        //                 //                       },
        //                 //                       symbol: {
        //                 //                         type: "CIMPolygonSymbol",
        //                 //                         symbolLayers: [
        //                 //                           {
        //                 //                             type: "CIMSolidFill",
        //                 //                             enable: true,
        //                 //                             color: [0, 0, 0, 30]
        //                 //                           }
        //                 //                         ]
        //                 //                       }
        //                 //                     }
        //                 //                   ],
        //                 //                   scaleSymbolsProportionally: true,
        //                 //                   respectFrame: true
        //                 //                 },
        //                 //                 {
        //                 //                   type: "CIMVectorMarker",
        //                 //                   enable: true,
        //                 //                   anchorPoint: { x: 0, y: 0 },
        //                 //                   anchorPointUnits: "Relative",
        //                 //                   primitiveName: "outerSizeOverride",
        //                 //                   frame: { xmin: 0.0, ymin: 0.0, xmax: 17.0, ymax: 17.0 },
        //                 //                   markerGraphics: [
        //                 //                     {
        //                 //                       type: "CIMMarkerGraphic",
        //                 //                       geometry: {
        //                 //                         rings: [
        //                 //                           [
        //                 //                             [8.5, 0.2],
        //                 //                             [7.06, 0.33],
        //                 //                             [5.66, 0.7],
        //                 //                             [4.35, 1.31],
        //                 //                             [3.16, 2.14],
        //                 //                             [2.14, 3.16],
        //                 //                             [1.31, 4.35],
        //                 //                             [0.7, 5.66],
        //                 //                             [0.33, 7.06],
        //                 //                             [0.2, 8.5],
        //                 //                             [0.33, 9.94],
        //                 //                             [0.7, 11.34],
        //                 //                             [1.31, 12.65],
        //                 //                             [2.14, 13.84],
        //                 //                             [3.16, 14.86],
        //                 //                             [4.35, 15.69],
        //                 //                             [5.66, 16.3],
        //                 //                             [7.06, 16.67],
        //                 //                             [8.5, 16.8],
        //                 //                             [9.94, 16.67],
        //                 //                             [11.34, 16.3],
        //                 //                             [12.65, 15.69],
        //                 //                             [13.84, 14.86],
        //                 //                             [14.86, 13.84],
        //                 //                             [15.69, 12.65],
        //                 //                             [16.3, 11.34],
        //                 //                             [16.67, 9.94],
        //                 //                             [16.8, 8.5],
        //                 //                             [16.67, 7.06],
        //                 //                             [16.3, 5.66],
        //                 //                             [15.69, 4.35],
        //                 //                             [14.86, 3.16],
        //                 //                             [13.84, 2.14],
        //                 //                             [12.65, 1.31],
        //                 //                             [11.34, 0.7],
        //                 //                             [9.94, 0.33],
        //                 //                             [8.5, 0.2]
        //                 //                           ]
        //                 //                         ]
        //                 //                       },
        //                 //                       symbol: {
        //                 //                         type: "CIMLineSymbol",
        //                 //                         symbolLayers: [
        //                 //                           {
        //                 //                             type: "CIMSolidStroke",
        //                 //                             enable: true,
        //                 //                             color: [150, 150, 150, 100],
        //                 //                             width: 1
        //                 //                           }
        //                 //                         ]
        //                 //                       }
        //                 //                     }
        //                 //                   ],
        //                 //                   scaleSymbolsProportionally: true,
        //                 //                   respectFrame: true
        //                 //                 }
        //                 //               ]
        //                 //             },
        //                 //             primitiveOverrides: [
        //                 //               {
        //                 //                 type: "CIMPrimitiveOverride",
        //                 //                 primitiveName: "outerSizeOverride",
        //                 //                 propertyName: "Size",
        //                 //                 valueExpressionInfo: {
        //                 //                   type: "CIMExpressionInfo",
        //                 //                   title: "Size in pixels of outer ring at maxScale",
        //                 //                   // the pixel size at the largest scale
        //                 //                   // 120 represents the pixel size of the
        //                 //                   // circles at the view's largest scale (1:2,311,161)
        //                 //                   expression: "70 * 577790 / $view.scale",
        //                 //                   returnType: "Default"
        //                 //                 }
        //                 //               },
        //                 //               {
        //                 //                 type: "CIMPrimitiveOverride",
        //                 //                 primitiveName: "innerSizeOverride",
        //                 //                 propertyName: "Size",
        //                 //                 valueExpressionInfo: {
        //                 //                   type: "CIMExpressionInfo",
        //                 //                   title: "Size in pixels of inner ring at maxScale",
        //                 //                   // outerSize is the pixel size at the largest scale
        //                 //                   // The innerSize is determined by multiplying
        //                 //                   // the outerSize by the forest ratio
        //                 //                   // var casesRatio = $feature.cases/`+casesShow+`;
        //                 //                   // casesShow
        //                 //                   // var casesRatio = (($feature.cases - previousDay)/previousDay);
        //                 //                   //   Console('innerSize=' + innerSize + ', casesRatio= ' + casesRatio + ', maxCases='+maxCases +' cases='+$feature.cases + ' minCases='+minCases  );
        //                 //                   // Console('innerSize=' + innerSize + ', per100kRatio= ' + casesRatio + ', maxper100k='+maxCases +' per100k='+$feature.per100k + ' minper100k='+minCases  );  
        //                 //                   // per100kMax=attributes.Max_per100k;
        //                 //                     // per100kMin=attributes.Min_per100k;
        //                 //                     // per100kSum=attributes.per100kSum;
        //                 //                   expression: `
        //                 //                     var maxCases = `+per100kMax+`;
        //                 //                     var minCases = `+per100kMin+`;
        //                 //                     var maxScale=68;
        //                 //                     var minScale=5;
        //                 //                     var casesRatio = ((maxScale - minScale) * ($feature.per100k - minCases) / (maxCases - minCases) + minScale);
                                            
        //                 //                     casesRatio = Round(casesRatio,1)/100;
        //                 //                     var outerSize = 70 * 577790 / $view.scale;
        //                 //                     var innerSize = outerSize * casesRatio;
        //                 //                     innerSize = IIF( casesRatio > 1, 68 * 577790 / $view.scale, innerSize );
                                            
        //                 //                     return IIF( innerSize < 3, 5, Round(innerSize,1) );
                                            
        //                 //                   `,
        //                 //                   returnType: "Default"
        //                 //                 //   return IIF( innerSize < .4, 3, innerSize );
        //                 //                 }
        //                 //               }
        //                 //             ]
        //                 //           }
        //                 //         },
        //                 //         visualVariables: [
        //                 //           {
        //                 //             type: "color",
        //                 //             field: "cases",
        //                 //             stops: [
        //                 //               {
        //                 //                 value: 153,
        //                 //                 color: "#31778E"
        //                 //               },
        //                 //               {
        //                 //                 value: 413,
        //                 //                 color: "#D0B285"
        //                 //               },
        //                 //               {
        //                 //                 value: 797,
        //                 //                 color: "#985914"
        //                 //               },
        //                 //               {
        //                 //                 value: 1352,
        //                 //                 color: "#533C19"
        //                 //               },
        //                 //               {
        //                 //                 value: 2117,
        //                 //                 color: "#F58C33"
        //                 //               },
        //                 //               {
        //                 //                   value: 3490,
        //                 //                   color: "#F9AC3D"
        //                 //               },
        //                 //               {
        //                 //                 value: 3734,
        //                 //                 color: "#CC643C"
        //                 //             },
        //                 //             {
        //                 //                 value: 15000,
        //                 //                 color: "#CF4917"
        //                 //             }
        //                 //             ]
        //                 //           }
        //                 //         ]
                                
        //                 //     };

        //                 // }else{
        //                 //     mapData.layerCases.renderer = {
                                
        //                 //         type: "simple",
        //                 //         symbol: {
        //                 //           type: "cim",
        //                 //           data: {
        //                 //             type: "CIMSymbolReference",
        //                 //             symbol: {
        //                 //               type: "CIMPointSymbol",
        //                 //               symbolLayers: [
        //                 //                 {
        //                 //                   type: "CIMVectorMarker",
        //                 //                   enable: true,
        //                 //                   anchorPoint: { x: 0, y: 0 },
        //                 //                   anchorPointUnits: "Relative",
        //                 //                   primitiveName: "innerSizeOverride",
        //                 //                   frame: { xmin: 0.0, ymin: 0.0, xmax: 17.0, ymax: 17.0 },
        //                 //                   markerGraphics: [
        //                 //                     {
        //                 //                       type: "CIMMarkerGraphic",
        //                 //                       geometry: {
        //                 //                         rings: [
        //                 //                           [
        //                 //                             [8.5, 0.2],
        //                 //                             [7.06, 0.33],
        //                 //                             [5.66, 0.7],
        //                 //                             [4.35, 1.31],
        //                 //                             [3.16, 2.14],
        //                 //                             [2.14, 3.16],
        //                 //                             [1.31, 4.35],
        //                 //                             [0.7, 5.66],
        //                 //                             [0.33, 7.06],
        //                 //                             [0.2, 8.5],
        //                 //                             [0.33, 9.94],
        //                 //                             [0.7, 11.34],
        //                 //                             [1.31, 12.65],
        //                 //                             [2.14, 13.84],
        //                 //                             [3.16, 14.86],
        //                 //                             [4.35, 15.69],
        //                 //                             [5.66, 16.3],
        //                 //                             [7.06, 16.67],
        //                 //                             [8.5, 16.8],
        //                 //                             [9.94, 16.67],
        //                 //                             [11.34, 16.3],
        //                 //                             [12.65, 15.69],
        //                 //                             [13.84, 14.86],
        //                 //                             [14.86, 13.84],
        //                 //                             [15.69, 12.65],
        //                 //                             [16.3, 11.34],
        //                 //                             [16.67, 9.94],
        //                 //                             [16.8, 8.5],
        //                 //                             [16.67, 7.06],
        //                 //                             [16.3, 5.66],
        //                 //                             [15.69, 4.35],
        //                 //                             [14.86, 3.16],
        //                 //                             [13.84, 2.14],
        //                 //                             [12.65, 1.31],
        //                 //                             [11.34, 0.7],
        //                 //                             [9.94, 0.33],
        //                 //                             [8.5, 0.2]
        //                 //                           ]
        //                 //                         ]
        //                 //                       },
        //                 //                       symbol: {
        //                 //                         type: "CIMPolygonSymbol",
        //                 //                         symbolLayers: [
        //                 //                           {
        //                 //                             type: "CIMSolidFill",
        //                 //                             enable: true,
        //                 //                             color: [0, 0, 0, 30]
        //                 //                           }
        //                 //                         ]
        //                 //                       }
        //                 //                     }
        //                 //                   ],
        //                 //                   scaleSymbolsProportionally: true,
        //                 //                   respectFrame: true
        //                 //                 },
        //                 //                 {
        //                 //                   type: "CIMVectorMarker",
        //                 //                   enable: true,
        //                 //                   anchorPoint: { x: 0, y: 0 },
        //                 //                   anchorPointUnits: "Relative",
        //                 //                   primitiveName: "outerSizeOverride",
        //                 //                   frame: { xmin: 0.0, ymin: 0.0, xmax: 17.0, ymax: 17.0 },
        //                 //                   markerGraphics: [
        //                 //                     {
        //                 //                       type: "CIMMarkerGraphic",
        //                 //                       geometry: {
        //                 //                         rings: [
        //                 //                           [
        //                 //                             [8.5, 0.2],
        //                 //                             [7.06, 0.33],
        //                 //                             [5.66, 0.7],
        //                 //                             [4.35, 1.31],
        //                 //                             [3.16, 2.14],
        //                 //                             [2.14, 3.16],
        //                 //                             [1.31, 4.35],
        //                 //                             [0.7, 5.66],
        //                 //                             [0.33, 7.06],
        //                 //                             [0.2, 8.5],
        //                 //                             [0.33, 9.94],
        //                 //                             [0.7, 11.34],
        //                 //                             [1.31, 12.65],
        //                 //                             [2.14, 13.84],
        //                 //                             [3.16, 14.86],
        //                 //                             [4.35, 15.69],
        //                 //                             [5.66, 16.3],
        //                 //                             [7.06, 16.67],
        //                 //                             [8.5, 16.8],
        //                 //                             [9.94, 16.67],
        //                 //                             [11.34, 16.3],
        //                 //                             [12.65, 15.69],
        //                 //                             [13.84, 14.86],
        //                 //                             [14.86, 13.84],
        //                 //                             [15.69, 12.65],
        //                 //                             [16.3, 11.34],
        //                 //                             [16.67, 9.94],
        //                 //                             [16.8, 8.5],
        //                 //                             [16.67, 7.06],
        //                 //                             [16.3, 5.66],
        //                 //                             [15.69, 4.35],
        //                 //                             [14.86, 3.16],
        //                 //                             [13.84, 2.14],
        //                 //                             [12.65, 1.31],
        //                 //                             [11.34, 0.7],
        //                 //                             [9.94, 0.33],
        //                 //                             [8.5, 0.2]
        //                 //                           ]
        //                 //                         ]
        //                 //                       },
        //                 //                       symbol: {
        //                 //                         type: "CIMLineSymbol",
        //                 //                         symbolLayers: [
        //                 //                           {
        //                 //                             type: "CIMSolidStroke",
        //                 //                             enable: true,
        //                 //                             color: [150, 150, 150, 100],
        //                 //                             width: 1
        //                 //                           }
        //                 //                         ]
        //                 //                       }
        //                 //                     }
        //                 //                   ],
        //                 //                   scaleSymbolsProportionally: true,
        //                 //                   respectFrame: true
        //                 //                 }
        //                 //               ]
        //                 //             },
        //                 //             primitiveOverrides: [
        //                 //               {
        //                 //                 type: "CIMPrimitiveOverride",
        //                 //                 primitiveName: "outerSizeOverride",
        //                 //                 propertyName: "Size",
        //                 //                 valueExpressionInfo: {
        //                 //                   type: "CIMExpressionInfo",
        //                 //                   title: "Size in pixels of outer ring at maxScale",
        //                 //                   // the pixel size at the largest scale
        //                 //                   // 120 represents the pixel size of the
        //                 //                   // circles at the view's largest scale (1:2,311,161)
        //                 //                   expression: "70 * 577790 / $view.scale",
        //                 //                   returnType: "Default"
        //                 //                 }
        //                 //               },
        //                 //               {
        //                 //                 type: "CIMPrimitiveOverride",
        //                 //                 primitiveName: "innerSizeOverride",
        //                 //                 propertyName: "Size",
        //                 //                 valueExpressionInfo: {
        //                 //                   type: "CIMExpressionInfo",
        //                 //                   title: "Size in pixels of inner ring at maxScale",
        //                 //                   // outerSize is the pixel size at the largest scale
        //                 //                   // The innerSize is determined by multiplying
        //                 //                   // the outerSize by the forest ratio
        //                 //                   // var casesRatio = $feature.cases/`+casesShow+`;
        //                 //                   // casesShow
        //                 //                   // var casesRatio = (($feature.cases - previousDay)/previousDay);
        //                 //                   //   Console('innerSize=' + innerSize + ', casesRatio= ' + casesRatio + ', maxCases='+maxCases +' cases='+$feature.cases + ' minCases='+minCases  );
        //                 //                   // Console('innerSize=' + innerSize + ', per100kRatio= ' + casesRatio + ', maxper100k='+maxCases +' per100k='+$feature.per100k + ' minper100k='+minCases  );  
        //                 //                   // per100kMax=attributes.Max_per100k;
        //                 //                   // per100kMin=attributes.Min_per100k;
        //                 //                   // per100kSum=attributes.per100kSum;
        //                 //                   // maxPer100kNew minPer100kNew


        //                 //                   // Testing - Changing to max min new cases
        //                 //                   // var maxCases = `+maxPer100kNew+`;
        //                 //                   // var minCases = `+minPer100kNew+`;

        //                 //                   expression: `
        //                 //                     var maxCases = `+maxNewCases+`;
        //                 //                     var minCases = `+minNewCases+`;
        //                 //                     var maxScale=68;
        //                 //                     var minScale=5;
        //                 //                     var casesRatio = ((maxScale - minScale) * ($feature.newcases - minCases) / (maxCases - minCases) + minScale);
                                            
        //                 //                     casesRatio = Round(casesRatio,1)/100;
        //                 //                     var outerSize = 70 * 577790 / $view.scale;
        //                 //                     var innerSize = outerSize * casesRatio;
        //                 //                     innerSize = IIF( casesRatio > 1, 68 * 577790 / $view.scale, innerSize );
                                            
        //                 //                     return IIF( innerSize < 3, 5, Round(innerSize,1) );
                                            
        //                 //                   `,
        //                 //                   returnType: "Default"
        //                 //                 //   return IIF( innerSize < .4, 3, innerSize );
        //                 //                 }
        //                 //               }
        //                 //             ]
        //                 //           }
        //                 //         },
        //                 //         visualVariables: [
        //                 //           {
        //                 //             type: "color",
        //                 //             field: "cases",
        //                 //             stops: [
        //                 //               {
        //                 //                 value: 153,
        //                 //                 color: "#31778E"
        //                 //               },
        //                 //               {
        //                 //                 value: 413,
        //                 //                 color: "#D0B285"
        //                 //               },
        //                 //               {
        //                 //                 value: 797,
        //                 //                 color: "#985914"
        //                 //               },
        //                 //               {
        //                 //                 value: 1352,
        //                 //                 color: "#533C19"
        //                 //               },
        //                 //               {
        //                 //                 value: 2117,
        //                 //                 color: "#F58C33"
        //                 //               },
        //                 //               {
        //                 //                   value: 3490,
        //                 //                   color: "#F9AC3D"
        //                 //               },
        //                 //               {
        //                 //                 value: 3734,
        //                 //                 color: "#CC643C"
        //                 //             },
        //                 //             {
        //                 //                 value: 15000,
        //                 //                 color: "#CF4917"
        //                 //             }
        //                 //             ]
        //                 //           }
        //                 //         ]
        //                 //     //   }
                                
        //                 //         // visualVariables: [
                                    
        //                 //         //     {
        //                 //         //         type: "class-breaks",
        //                 //         //         // minDataValue: 1,
        //                 //         //         // maxDataValue: 9500,
        //                 //         //         field: "cases",
        //                 //         //         stops: [
        //                 //         //             { value: 900, size: "6px" },
        //                 //         //             { value: 3831, size: "10.5px" },
        //                 //         //             { value: 8419, size: "15px" },
        //                 //         //             { value: 15011, size: "19.5px" },
        //                 //         //             { value: 29276, size: "24px" },
        //                 //         //             { value: 64915, size: "28.5px" },
        //                 //         //             { value: 146787, size: "33px" },
        //                 //         //             { value: 223725, size: "37px" }
        //                 //         //         ],
        //                 //         //         // TESTING STOPS
        //                 //         //         // stops: [
        //                 //         //         //     { value: 1200, size: "4px" },
        //                 //         //         //     { value: 4200, size: "8px" },
        //                 //         //         //     { value: 9000, size: "16px" },
        //                 //         //         //     { value: 16000, size: "45px" },
        //                 //         //         //     { value: 31000, size: "70px" },
        //                 //         //         //     { value: 64000, size: "105px" },
        //                 //         //         //     { value: 133000, size: "120px" },
        //                 //         //         //     { value: 223000, size: "175px" },
        //                 //         //         //     // { value: 135000, size: "175px" },
        //                 //         //         //     // { value: 220000, size: "250px" },
        //                 //         //         // ],
        //                 //         //         // OG STOPS
        //                 //         //         // stops: [
        //                 //         //         //     { value: 1, size: "3px" },
        //                 //         //         //     { value: 10, size: "7px" },
        //                 //         //         //     { value: 100, size: "15px" },
        //                 //         //         //     { value: 500, size: "35px" },
        //                 //         //         //     { value: 1000, size: "55px" },
        //                 //         //         //     { value: 2500, size: "75px" },
        //                 //         //         //     { value: 5000, size: "125px" },
        //                 //         //         //     { value: 7500, size: "175px" }
        //                 //         //         // ],
        //                 //         //         // minSize: 1,
        //                 //         //         // maxSize: 150,
        //                 //         //         valueExpressionTitle: "Cases",
        //                 //         //         valueUnit: "unknown"
        //                 //         //     }
        //                 //         // ]
        //                 //     };

        //                 // }

        //                 // that.setState({
        //                 //     records:result.features,
        //                 //     dateRange:mapData.timeSlider.timeExtent.start.toLocaleDateString(),
        //                 //     totalPer100K:per100kTotalnew,
        //                 //     totalPop:abbreviate(totalPop,3,3,'M'),
        //                 //     totalCases:totalCases,
        //                 //     newcases:totalNewCases,
        //                 //     newcasesP:casePercentChangeNew,
        //                 //     newcasesColor:"",
        //                 //     totalDeath:totalDeaths,
        //                 //     totalDeathP:deathPercentNew,
        //                 //     deathPChange:deathChangePercentNew,
        //                 //     deathPColor:deathPColor,
        //                 //     newDeath:totalNewDeaths,
        //                 //     moveDate:mapData.timeSlider.timeExtent.start
        //                 // });
        //             }
        //         }

        //     }).catch(function(error) {
        //         console.log(error);
        //     });


    prevStart=start;
    prevStop=stop;

}

export async function setStatePopUp(mode,state,start,stop,stateFull,mapData){
    let aggrData=[];
    let previousState=localStorage.getItem('previousState');
    let prevaggrData=JSON.parse(localStorage.getItem('prevaggrData'));
    var nodePopUp=document.createElement("div");
    if(mode==="State"){
        //check if we are in single or multiple day mode
        if(moment(start.getTime()).format('l')!==moment(stop.getTime()).format('l')){
            //should not hit here
        }else{
            if(previousState===state){
                aggrData=prevaggrData;
                ReactDOM.render(<MapPopUpDiv point={mapData.point} redCrossLPHE={mapData.redCrossLPHE} socialDistanceLayer={mapData.socialDistanceLayer} theData={aggrData} mode={mode} view={mapData.view} stateFull={stateFull} stateAbbrv={state}></MapPopUpDiv>,nodePopUp);
            }else{
                let qsc;
                try{
                    qsc = await queryStateCases(state,mapData.layerCases)
                }catch(err){
                    console.log(err);
                }
                if(_.get(qsc,"features.length",0)>0){
                    aggrData=[];
                    aggrData=_.chain(qsc.features).groupBy("attributes.date").map(function(v, i) {return {date: i,counties: _.map(v, 'attributes')}}).value();
                    localStorage.setItem('previousState', state);
                    localStorage.setItem('currentState', state);
                    localStorage.setItem('prevaggrData', JSON.stringify(aggrData));
                    ReactDOM.render(<MapPopUpDiv point={mapData.point} redCrossLPHE={mapData.redCrossLPHE} socialDistanceLayer={mapData.socialDistanceLayer} theData={aggrData} mode={mode} view={mapData.view} stateFull={stateFull} stateAbbrv={state}></MapPopUpDiv>,nodePopUp);

                }else{

                }
            }
        }
        return nodePopUp;
    }else if(mode==="StateLex"){
        if(moment(start.getTime()).format('l')!==moment(stop.getTime()).format('l')){
            //should not hit here
        }else{
            if(previousState===state){
                aggrData=prevaggrData;
                ReactDOM.render(<MapPopUpDiv point={mapData.point} redCrossLPHE={mapData.redCrossLPHE} socialDistanceLayer={mapData.socialDistanceLayer} theData={aggrData} mode={mode} view={mapData.view} stateFull={stateFull} stateAbbrv={state}></MapPopUpDiv>,nodePopUp);
            }else{
                let qsc;
                try{
                    qsc = await queryStateCases(state,mapData.layerCases)
                }catch(err){
                    console.log(err);
                }
                if(_.get(qsc,"features.length",0)>0){
                    aggrData=[];
                    aggrData=_.chain(qsc.features).groupBy("attributes.date").map(function(v, i) {return {date: i,counties: _.map(v, 'attributes')}}).value();
                    localStorage.setItem('previousState', state);
                    localStorage.setItem('prevaggrData', JSON.stringify(aggrData));
                    ReactDOM.render(<MapPopUpDiv point={mapData.point} redCrossLPHE={mapData.redCrossLPHE} socialDistanceLayer={mapData.socialDistanceLayer} theData={aggrData} mode={mode} view={mapData.view} stateFull={stateFull} stateAbbrv={state}></MapPopUpDiv>,nodePopUp);

                }else{

                }

            }

        }
        return nodePopUp;
    }else{

    }
}

export async function setCountyPopUp(mode,state,fips,start,stop,countyFull,mapData){
    let aggrData=[];
    let qCounty;
    let previousCounty=localStorage.getItem('previousCounty');
    let prevaggrDataCounty=JSON.parse(localStorage.getItem('prevaggrDataCounty'));
    var nodePopUp=document.createElement("div");

    if(mode==="County"){
        if(moment(start.getTime()).format('l')!==moment(stop.getTime()).format('l')){
            //shouldnt hit this but its here if we need it
        }else{
            if(previousCounty===fips){
                aggrData=prevaggrDataCounty;
                ReactDOM.render(<MapPopUpDiv point={mapData.point} redCrossLPHE={mapData.redCrossLPHE} socialDistanceLayer={mapData.socialDistanceLayer} theData={aggrData} mode={mode} view={mapData.view} countyFips={fips} countyFull={countyFull} stateAbbrv={state}></MapPopUpDiv>,nodePopUp);
            }else{
                try{
                    qCounty = await queryCountyCases(fips,state,mapData.layerCases)
                }catch(err){
                    console.log(err);
                }
                if(_.get(qCounty,"features.length",0)>0){
                    aggrData=_.chain(qCounty.features).groupBy("attributes.date").map(function(v, i) {return {date: i,counties: _.map(v, 'attributes')}}).value();
                    localStorage.setItem('previousCounty', fips);
                    localStorage.setItem('prevaggrDataCounty', JSON.stringify(aggrData));
                    ReactDOM.render(<MapPopUpDiv point={mapData.point} redCrossLPHE={mapData.redCrossLPHE} socialDistanceLayer={mapData.socialDistanceLayer} theData={aggrData} mode={mode} view={mapData.view} countyFips={fips} countyFull={countyFull} stateAbbrv={state}></MapPopUpDiv>,nodePopUp);

                }else{
                    //query failed show error
                }
            }
        }
        return nodePopUp;
    }else if(mode==="CountyLex"){
        
        if(moment(start.getTime()).format('l')!==moment(stop.getTime()).format('l')){
            //shouldnt hit this but its here if we need it
        }else{
            if(previousCounty===fips){
                aggrData=prevaggrDataCounty;
                ReactDOM.render(<MapPopUpDiv point={mapData.point} redCrossLPHE={mapData.redCrossLPHE} socialDistanceLayer={mapData.socialDistanceLayer} theData={aggrData} mode={mode} view={mapData.view} countyFips={fips} countyFull={countyFull} stateAbbrv={state}></MapPopUpDiv>,nodePopUp);
            }else{
                try{
                    qCounty = await queryCountyCases(fips,state,mapData.layerCases)
                }catch(err){
                    console.log(err);
                }
                if(_.get(qCounty,"features.length",0)>0){
                    aggrData=_.chain(qCounty.features).groupBy("attributes.date").map(function(v, i) {return {date: i,counties: _.map(v, 'attributes')}}).value();
                    localStorage.setItem('previousCounty', fips);
                    localStorage.setItem('prevaggrDataCounty', JSON.stringify(aggrData));
                    ReactDOM.render(<MapPopUpDiv point={mapData.point} redCrossLPHE={mapData.redCrossLPHE} socialDistanceLayer={mapData.socialDistanceLayer} theData={aggrData} mode={mode} view={mapData.view} countyFips={fips} countyFull={countyFull} stateAbbrv={state}></MapPopUpDiv>,nodePopUp);

                }else{
                    //query failed show error
                }
            }
        }
        return nodePopUp;
    }
}

export async function queryStateCases(state,layerCases){
    if(state){
        let query = layerCases.createQuery();
        query.where = "st_abbrev='" + state+"'";
        query.returnGeometry=false;
        query.fields=["*"]
        let sq = await layerCases.queryFeatures(query);
        return sq;
    }
    
}

export async function queryCountyCases(fips,state,layerCases){
    var query = layerCases.createQuery();
    query.where = "fipscovid='" + fips+"' AND st_abbrev='"+state+"'";
    query.returnGeometry=false;
    query.fields=["*"];
    let qcc = await layerCases.queryFeatures(query);
    return qcc;
}

export async function queryAllGroupStat(layerCases){
    if(layerCases){
        let query = layerCases.createQuery();
        query.outStatistics = [
            caseCount,
            newcasesCount,
            deathCount,
            newDeathCount,
            pop19Sum,
            caseMax,
            deathMax,
            maxPop
        ];
        // query.having = "date='2020-04-19'";
        query.groupByFieldsForStatistics = [ "groupStateCounty" ]
        // query.groupByFieldsForStatistics = [ "st_abbrev" ]
        
        query.returnGeometry=false;
        query.fields=[]
        let sq = await layerCases.queryFeatures(query);
        return sq;
    }

    
    console.log("YAYA");


}


export function between(x, min, max) {
    return x > min && x < max;
}

export function abbreviate(number, maxPlaces, forcePlaces, forceLetter) {
    number = Number(number)
    forceLetter = forceLetter || false
    if(forceLetter !== false) {
      return annotate(number, maxPlaces, forcePlaces, forceLetter)
    }
    var abbr
    if(number >= 1e12) {
      abbr = 'T'
    }
    else if(number >= 1e9) {
      abbr = 'B'
    }
    else if(number >= 1e6) {
      abbr = 'M'
    }
    else if(number >= 1e3) {
      abbr = 'K'
    }
    else {
      abbr = ''
    }
    return annotate(number, maxPlaces, forcePlaces, abbr)
}

export function annotate(number, maxPlaces, forcePlaces, abbr) {
    // set places to false to not round
    var rounded = 0
    switch(abbr) {
        case 'T':
        rounded = number / 1e12
        break
        case 'B':
        rounded = number / 1e9
        break
        case 'M':
        rounded = number / 1e6
        break
        case 'K':
        rounded = number / 1e3
        break
        case '':
        rounded = number
        break
    }
    if(maxPlaces !== false) {
        var test = new RegExp('\\.\\d{' + (maxPlaces + 1) + ',}$')
        if(test.test(('' + rounded))) {
        rounded = rounded.toFixed(maxPlaces)
        }
    }
    if(forcePlaces !== false) {
        rounded = Number(rounded).toFixed(forcePlaces)
    }
    return rounded + abbr
}

export function formatState(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
}

export const per100kSum = {
    onStatisticField: "per100k",
    outStatisticFieldName: "per100kSum",
    statisticType: "sum"
};

export const per100kMax = {
    onStatisticField: "per100k",
    outStatisticFieldName: "Max_per100k",
    statisticType: "max"
};

export const per100kMin = {
    onStatisticField: "per100k",
    outStatisticFieldName: "Min_per100k",
    statisticType: "min"
};

export const pop19Sum = {
    onStatisticField: "totpop_19",
    outStatisticFieldName: "pop19Sum",
    statisticType: "sum"
};

export const pop19Max = {
  onStatisticField: "totpop_19",
  outStatisticFieldName: "pop19Max",
  statisticType: "max"
};

export const caseMax = {
    onStatisticField: "cases",
    outStatisticFieldName: "Max_cases",
    statisticType: "max"
};

export const caseMin = {
    onStatisticField: "cases",
    outStatisticFieldName: "Min_cases",
    statisticType: "min"
};

export const caseAvg = {
    onStatisticField: "cases",
    outStatisticFieldName: "Average_cases",
    statisticType: "avg"
};

export const caseCount = {
    onStatisticField: "cases",
    outStatisticFieldName: "caseCount",
    statisticType: "sum"
};

export const newcasesCount = {
    onStatisticField: "newcases",
    outStatisticFieldName: "newcasesCount",
    statisticType: "sum"
};

export const newcasesMax = {
    onStatisticField: "newcases",
    outStatisticFieldName: "Max_newcases",
    statisticType: "max"
};

export const newcasesMin = {
  onStatisticField: "newcases",
  outStatisticFieldName: "Min_newcases",
  statisticType: "min"
};

export const deathCount = {
    onStatisticField: "deaths",
    outStatisticFieldName: "deathCount",
    statisticType: "sum"
};

export const newDeathCount = {
    onStatisticField: "newdeaths",
    outStatisticFieldName: "newDeathCount",
    statisticType: "sum"
};

export const newDeathMax = {
  onStatisticField: "newdeaths",
  outStatisticFieldName: "newDeathMax",
  statisticType: "max"
};

export const deathMax = {
    onStatisticField: "deaths",
    outStatisticFieldName: "maxDeaths",
    statisticType: "max"
};

export const stateName = {
    onStatisticField: "st_abbrev",
    outStatisticFieldName: "st_abbrev",
    statisticType: "max"
};

export const maxPop = {
    onStatisticField: "totpop_19",
    outStatisticFieldName: "totpop_19",
    statisticType: "max"
};

export const theFlag = {
    onStatisticField: "flag",
    outStatisticFieldName: "flag",
    statisticType: "max"
};

export const recordCount = {
  onStatisticField:"cases",
  outStatisticFieldName: "recordCount",
  statisticType:"count"
};

export const maxObjectID = {
  onStatisticField:"objectid",
  outStatisticFieldName: "maxObjectID",
  statisticType:"max"
};

export const maxfips = {
  onStatisticField:"fipsgj",
  outStatisticFieldName: "maxfips",
  statisticType:"max"
};

export const statsFields = {
    caseMax: "Max Cases",
    caseAvg: "Average Cases",
    caseCount:"Overall Cases",
    deathCount:"Overall Deaths",
    pop19Sum:"Overall Population"

};