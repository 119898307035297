let fig = {
    app:{
        title:"US COVID-19 Cases/Deaths",
        updated:{
            url:"https://gis.aacounty.org/arcgis/rest/services/Hosted/Data_By_Date/FeatureServer/3"
        }
    },
    random: {
        number:"100000000"
    },
    auth: {
        level:"org_admin",
        contentStatus:"org_authoritative"
    },
    // site: {
    //     url: url
    // },
    geoProxy: {
        url:"https://oitgis.aacounty.org/gis/geoproxy/proxy.ashx"
    },
    map:{
        center: {
            long:-77.3613,
            lat:39.0858
            // long:-96.24875156249819,
            // lat:37.63406291430992
            
        }
    },
    layers:{
        cases:{
            // https://g1oos8yxz5.execute-api.us-east-1.amazonaws.com/Prod/service/covid19/FeatureServer/0/query
            url1:"https://gis.aacounty.org/arcgis/rest/services/Hosted/COVID19_Cases_Date/FeatureServer/2",
            url2:"https://gis.aacounty.org/arcgis/rest/services/Hosted/COVID19_Cases_Date/FeatureServer/3",
            urlCountyStat:"https://gis.aacounty.org/arcgis/rest/services/Hosted/COVID19_Cases_County_Stat/FeatureServer/0",
            urlKoop:"https://api.awamaps.com/gis/covid19/FeatureServer/0",
            copyright: "The New York Times",
            title: "COVID-19 Cases/Deaths By Date",
            excludeEffects:"grayscale(10%) opacity(65%)",
            // excludeEffectSingle:"grayscale(100%) opacity(15%)",
            includedEffects:"opacity(80%)"
        },
        base:{
            urlTemplate: "https://stamen-tiles-{subDomain}.a.ssl.fastly.net/toner/{level}/{col}/{row}.png",
            subDomains: ['a', 'b', 'c', 'd'],
            title:"Toner",
            copyright:"Stamen Toner"
        },
        states:{
            url:"https://gis.aacounty.org/arcgis/rest/services/Hosted/COVID19_Cases_Date/FeatureServer/1",
        },
        counties:{
            url:"https://gis.aacounty.org/arcgis/rest/services/Hosted/COVID19_Cases_Date/FeatureServer/0",
            stateLookup:{
                AZ: 'Arizona',
                AL: 'Alabama',
                AK: 'Alaska',
                AR: 'Arkansas',
                CA: 'California',
                CO: 'Colorado',
                CT: 'Connecticut',
                DC: 'District of Columbia',
                DE: 'Delaware',
                FL: 'Florida',
                GA: 'Georgia',
                HI: 'Hawaii',
                ID: 'Idaho',
                IL: 'Illinois',
                IN: 'Indiana',
                IA: 'Iowa',
                KS: 'Kansas',
                KY: 'Kentucky',
                LA: 'Louisiana',
                ME: 'Maine',
                MD: 'Maryland',
                MA: 'Massachusetts',
                MI: 'Michigan',
                MN: 'Minnesota',
                MS: 'Mississippi',
                MO: 'Missouri',
                MT: 'Montana',
                NE: 'Nebraska',
                NV: 'Nevada',
                NH: 'New Hampshire',
                NJ: 'New Jersey',
                NM: 'New Mexico',
                NY: 'New York',
                NC: 'North Carolina',
                ND: 'North Dakota',
                OH: 'Ohio',
                OK: 'Oklahoma',
                OR: 'Oregon',
                PA: 'Pennsylvania',
                RI: 'Rhode Island',
                SC: 'South Carolina',
                SD: 'South Dakota',
                TN: 'Tennessee',
                TX: 'Texas',
                UT: 'Utah',
                VT: 'Vermont',
                VA: 'Virginia',
                WA: 'Washington',
                WV: 'West Virginia',
                WI: 'Wisconsin',
                WY: 'Wyoming',
                AS: "American Samoa",
                GU: "Guam",
                MP: "Northern Mariana Islands",
                PR: "Puerto Rico",
                VI: "U.S. Virgin Islands",
                UM: "U.S. Minor Outlying Islands",
            }
        },
        redCrossLPHE:{
            url:"https://services.arcgis.com/pGfbNJoYypmNq86F/arcgis/rest/services/COVID19_Public_Health_Status_by_County/FeatureServer/0",
            title:"COVID-19 Local Public Health Emergency",
            copyright: "The Red Cross"
        },
        socialDistanceGrade:{
            url:"https://services.arcgis.com/DO4gTjwJVIJ7O9Ca/arcgis/rest/services/Unacast_Latest_Available__Visitation_and_Distance_/FeatureServer/0",
            title:"Unacast Social Distancing Score",
            copyright:"Unacast",
            rangeValues:{
                A:{
                    max:5,
                    min:4.5
                },
                AM:{
                    max:4.5,
                    min:4.0
                },
                B:{
                    max:4.0,
                    min:3.5
                },
                BM:{
                    max:3.5,
                    min:3.0
                },
                C:{
                    max:3.0,
                    min:2.5
                },
                CM:{
                    max:2.5,
                    min:2.0
                },
                D:{
                    max:2.0,
                    min:1.5
                },
                DM:{
                    max:1.5,
                    min:1.0
                },
                F:{
                    max:1,
                    min:0
                },

            }
            
        }
    },
    dataExceptions:{
        NewYorkCityNY:{
            county:"New York City",
            state:"NY",
            note:"All cases for the five boroughs of New York City (New York, Kings, Queens, Bronx and Richmond counties) are assigned to a single area called New York City."
        },
        KansasCityMo:{
            county:"Kansas City",
            state:"MO",
            note:"Four counties (Cass, Clay, Jackson and Platte) overlap the municipality of Kansas City, Mo. The cases and deaths that we show for these four counties are only for the portions exclusive of Kansas City. Cases and deaths for Kansas City are reported as their own line."
        },
        AlamedaCA:{
            county:"Alameda",
            state:"CA",
            note:"Counts for Alameda County include cases and deaths from Berkeley and the Grand Princess cruise ship."
        },
        ChicagoIL:{
            county:"Cook",
            state:"IL",
            note:"All cases and deaths for Chicago are reported as part of Cook County."
        },
        Guam:{
            county:"Guam",
            state:"GU",
            note:"Counts for Guam include cases reported from the USS Theodore Roosevelt."
        }


    }
}

let config=fig;

export default config