import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import config from '../../config';
import Spinner from 'react-bootstrap/Spinner'
import { between } from '../../Resources/functions/Functions';
import { ResponsiveBubble,ResponsiveBar } from 'nivo'

class TheChartDiv extends Component{
    _isMounted=false;
    constructor(props){
        super(props)
        this.state={
            root:this.props.root,
            root2:{
                "name": "root",
                "children": [
                  {
                    "name": "node.0",
                    "value": 53652
                  },
                  {
                    "name": "node.1",
                    "value": 56067
                  },
                  {
                    "name": "node.2",
                    "value": 40963
                  },
                  {
                    "name": "node.3",
                    "value": 71958
                  },
                  {
                    "name": "node.4",
                    "value": 61076
                  },
                  {
                    "name": "node.5",
                    "value": 78106
                  },
                  {
                    "name": "node.6",
                    "value": 64561
                  },
                  {
                    "name": "node.7",
                    "value": 96621
                  },
                  {
                    "name": "node.8",
                    "value": 68498
                  },
                  {
                    "name": "node.9",
                    "value": 3160
                  },
                  {
                    "name": "node.10",
                    "value": 84646
                  },
                  {
                    "name": "node.11",
                    "value": 12908
                  },
                  {
                    "name": "node.12",
                    "value": 56116
                  },
                  {
                    "name": "node.13",
                    "value": 35870
                  },
                  {
                    "name": "node.14",
                    "value": 17572
                  },
                  {
                    "name": "node.15",
                    "value": 64190
                  },
                  {
                    "name": "node.16",
                    "value": 15142
                  },
                  {
                    "name": "node.17",
                    "value": 36461
                  },
                  {
                    "name": "node.18",
                    "value": 82348
                  },
                  {
                    "name": "node.19",
                    "value": 27391
                  },
                  {
                    "name": "node.20",
                    "value": 27212
                  },
                  {
                    "name": "node.21",
                    "value": 19536
                  },
                  {
                    "name": "node.22",
                    "value": 82603
                  },
                  {
                    "name": "node.23",
                    "value": 879
                  },
                  {
                    "name": "node.24",
                    "value": 83167
                  },
                  {
                    "name": "node.25",
                    "value": 70671
                  },
                  {
                    "name": "node.26",
                    "value": 14287
                  },
                  {
                    "name": "node.27",
                    "value": 75430
                  },
                  {
                    "name": "node.28",
                    "value": 59204
                  },
                  {
                    "name": "node.29",
                    "value": 16339
                  },
                  {
                    "name": "node.30",
                    "value": 86638
                  },
                  {
                    "name": "node.31",
                    "value": 36258
                  },
                  {
                    "name": "node.32",
                    "value": 92666
                  },
                  {
                    "name": "node.33",
                    "value": 10365
                  },
                  {
                    "name": "node.34",
                    "value": 81908
                  },
                  {
                    "name": "node.35",
                    "value": 43436
                  },
                  {
                    "name": "node.36",
                    "value": 83418
                  },
                  {
                    "name": "node.37",
                    "value": 28209
                  },
                  {
                    "name": "node.38",
                    "value": 79736
                  },
                  {
                    "name": "node.39",
                    "value": 87131
                  },
                  {
                    "name": "node.40",
                    "value": 74862
                  },
                  {
                    "name": "node.41",
                    "value": 14733
                  },
                  {
                    "name": "node.42",
                    "value": 81316
                  },
                  {
                    "name": "node.43",
                    "value": 69034
                  },
                  {
                    "name": "node.44",
                    "value": 71904
                  },
                  {
                    "name": "node.45",
                    "value": 86885
                  },
                  {
                    "name": "node.46",
                    "value": 15878
                  },
                  {
                    "name": "node.47",
                    "value": 57853
                  },
                  {
                    "name": "node.48",
                    "value": 84580
                  },
                  {
                    "name": "node.49",
                    "value": 19455
                  },
                  {
                    "name": "node.50",
                    "value": 45496
                  },
                  {
                    "name": "node.51",
                    "value": 85805
                  },
                  {
                    "name": "node.52",
                    "value": 10482
                  },
                  {
                    "name": "node.53",
                    "value": 20175
                  },
                  {
                    "name": "node.54",
                    "value": 16427
                  },
                  {
                    "name": "node.55",
                    "value": 79214
                  },
                  {
                    "name": "node.56",
                    "value": 25337
                  },
                  {
                    "name": "node.57",
                    "value": 92659
                  },
                  {
                    "name": "node.58",
                    "value": 40561
                  },
                  {
                    "name": "node.59",
                    "value": 58120
                  },
                  {
                    "name": "node.60",
                    "value": 87558
                  },
                  {
                    "name": "node.61",
                    "value": 33386
                  },
                  {
                    "name": "node.62",
                    "value": 20712
                  },
                  {
                    "name": "node.63",
                    "value": 28536
                  },
                  {
                    "name": "node.64",
                    "value": 93871
                  },
                  {
                    "name": "node.65",
                    "value": 56625
                  },
                  {
                    "name": "node.66",
                    "value": 29510
                  },
                  {
                    "name": "node.67",
                    "value": 23739
                  },
                  {
                    "name": "node.68",
                    "value": 31039
                  },
                  {
                    "name": "node.69",
                    "value": 99054
                  },
                  {
                    "name": "node.70",
                    "value": 51232
                  },
                  {
                    "name": "node.71",
                    "value": 34060
                  },
                  {
                    "name": "node.72",
                    "value": 45526
                  },
                  {
                    "name": "node.73",
                    "value": 47200
                  },
                  {
                    "name": "node.74",
                    "value": 90150
                  },
                  {
                    "name": "node.75",
                    "value": 41121
                  },
                  {
                    "name": "node.76",
                    "value": 36964
                  },
                  {
                    "name": "node.77",
                    "value": 45775
                  },
                  {
                    "name": "node.78",
                    "value": 63875
                  },
                  {
                    "name": "node.79",
                    "value": 51037
                  },
                  {
                    "name": "node.80",
                    "value": 16986
                  },
                  {
                    "name": "node.81",
                    "value": 68183
                  },
                  {
                    "name": "node.82",
                    "value": 59268
                  },
                  {
                    "name": "node.83",
                    "value": 74442
                  },
                  {
                    "name": "node.84",
                    "value": 65346
                  },
                  {
                    "name": "node.85",
                    "value": 37694
                  },
                  {
                    "name": "node.86",
                    "value": 26794
                  },
                  {
                    "name": "node.87",
                    "value": 85226
                  },
                  {
                    "name": "node.88",
                    "value": 98484
                  },
                  {
                    "name": "node.89",
                    "value": 53593
                  },
                  {
                    "name": "node.90",
                    "value": 63477
                  },
                  {
                    "name": "node.91",
                    "value": 3560
                  },
                  {
                    "name": "node.92",
                    "value": 694
                  },
                  {
                    "name": "node.93",
                    "value": 734
                  },
                  {
                    "name": "node.94",
                    "value": 23149
                  },
                  {
                    "name": "node.95",
                    "value": 75846
                  },
                  {
                    "name": "node.96",
                    "value": 35979
                  },
                  {
                    "name": "node.97",
                    "value": 75491
                  },
                  {
                    "name": "node.98",
                    "value": 26603
                  },
                  {
                    "name": "node.99",
                    "value": 36047
                  },
                  {
                    "name": "node.100",
                    "value": 68190
                  },
                  {
                    "name": "node.101",
                    "value": 36170
                  },
                  {
                    "name": "node.102",
                    "value": 21025
                  },
                  {
                    "name": "node.103",
                    "value": 21466
                  },
                  {
                    "name": "node.104",
                    "value": 53206
                  },
                  {
                    "name": "node.105",
                    "value": 96884
                  },
                  {
                    "name": "node.106",
                    "value": 57987
                  },
                  {
                    "name": "node.107",
                    "value": 10654
                  },
                  {
                    "name": "node.108",
                    "value": 92717
                  },
                  {
                    "name": "node.109",
                    "value": 28118
                  },
                  {
                    "name": "node.110",
                    "value": 86402
                  },
                  {
                    "name": "node.111",
                    "value": 68059
                  },
                  {
                    "name": "node.112",
                    "value": 41894
                  },
                  {
                    "name": "node.113",
                    "value": 78529
                  },
                  {
                    "name": "node.114",
                    "value": 30594
                  },
                  {
                    "name": "node.115",
                    "value": 2972
                  },
                  {
                    "name": "node.116",
                    "value": 63144
                  },
                  {
                    "name": "node.117",
                    "value": 27927
                  },
                  {
                    "name": "node.118",
                    "value": 60098
                  },
                  {
                    "name": "node.119",
                    "value": 49019
                  },
                  {
                    "name": "node.120",
                    "value": 84169
                  },
                  {
                    "name": "node.121",
                    "value": 34470
                  },
                  {
                    "name": "node.122",
                    "value": 55337
                  },
                  {
                    "name": "node.123",
                    "value": 27374
                  },
                  {
                    "name": "node.124",
                    "value": 52783
                  },
                  {
                    "name": "node.125",
                    "value": 76771
                  },
                  {
                    "name": "node.126",
                    "value": 2129
                  },
                  {
                    "name": "node.127",
                    "value": 34481
                  },
                  {
                    "name": "node.128",
                    "value": 61185
                  },
                  {
                    "name": "node.129",
                    "value": 69845
                  },
                  {
                    "name": "node.130",
                    "value": 32394
                  },
                  {
                    "name": "node.131",
                    "value": 9886
                  },
                  {
                    "name": "node.132",
                    "value": 22276
                  },
                  {
                    "name": "node.133",
                    "value": 18731
                  },
                  {
                    "name": "node.134",
                    "value": 99455
                  },
                  {
                    "name": "node.135",
                    "value": 83115
                  },
                  {
                    "name": "node.136",
                    "value": 49280
                  },
                  {
                    "name": "node.137",
                    "value": 46577
                  },
                  {
                    "name": "node.138",
                    "value": 19251
                  }
                ]
            },
            root3:{
                "name": "root",
                "children": this.props.root2
            }
        }
    }

    
    
    render(){
        console.log(this.state.root2)
        console.log(this.state.root3)
        return(
            <>
            {/* <ResponsiveBar
                data={this.state.root}
                keys={[ 'Cases', 'Deaths' ]}
                indexBy="County"
                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                padding={0.3}
                colors={{ scheme: 'nivo' }}
                
                fill="rgb(232, 193, 160)"
                borderColor="#000000"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'County',
                    legendPosition: 'center',
                    legendOffset: 32
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Count',
                    legendPosition: 'center',
                    legendOffset: -40
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
               
                labelTextColor="#737373"
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
            /> */}
        <div style={{height:"300px"}}>
        <ResponsiveBubble
                root={this.state.root3}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                identity="name"
                value="value"
                // colors={{ scheme: 'yellow_orange_red' }}
                tooltipFormat={value =>
                    `${Number(value).toLocaleString('ru-RU', {
                        minimumFractionDigits: 2,
                    })} ₽`
                }
                fill="rgba(198, 45, 205, 0.8)"
                colorBy="value"
                leavesOnly={true}
                enableLabel={true}
                label="name"
                labelSkipRadius={10}
                labelTextColor="#ffffff"
                borderColor="#000000"
                animate={true}
                motionStiffness={90}
                motionDamping={12}
            />
            </div>
        </>
        
        )
    }

}



class MapPopUpDiv extends Component{
    _isMounted=false;
    constructor(props){
        super(props)
        this.state={
            mode:this.props.mode,
            loaded:false,
            theView:this.props.view,
            redCrossLPHE:this.props.redCrossLPHE,
            socialDistanceLayer:this.props.socialDistanceLayer,
            thePoint:this.props.point,
            showChart:false,
            theText:"",
            stateFull:this.props.stateFull,
            stateAbbrv:this.props.stateAbbrv,
            countyFips:this.props.countyFips,
            countyFull:this.props.countyFull,
            theData:this.props.theData,
            thePopupData:{
                nytNote:"Loading NYT Note...",
                introText:"Loading...",
                redCrossLine:"Loading Red Cross Data...",
                socialDistance:{
                    line:"Loading Social Distance Score...",
                    data:[]
                }
            }
        };
        this.queryRedCross = this.queryRedCross.bind(this);
        this.querySocialDistancePoint = this.querySocialDistancePoint.bind(this);
        this.querySocialDistanceState = this.querySocialDistanceState.bind(this);
    }

    async queryRedCross(mode,point,redCrossLPHE,fips,state){
        console.log("YES")
        let query = redCrossLPHE.createQuery();
        if(mode==="State" || mode==="County"){
            if(point && redCrossLPHE){
                query.geometry=point;
                query.fields=["*"];
                let rcq = await redCrossLPHE.queryFeatures(query);
                return rcq;
            }
        }else{
            //it will be a lex call so we q by attributes over point
            if(mode==="CountyLex"){
                query.where="fips='"+fips+"'";
                query.fields=["*"];
                let rcq = await redCrossLPHE.queryFeatures(query);
                return rcq;
            }else{
                //state q
                console.log("JERE")
                query.where="county_st='"+state+"'";
                query.fields=["*"];
                let rcq = await redCrossLPHE.queryFeatures(query);
                return rcq;
            }
            


        }
        
    }

    async querySocialDistancePoint(mode,point,socialDistanceLayer,fips){
        let query = socialDistanceLayer.createQuery();
        if(mode==="State" || mode==="County"){
            if(point && socialDistanceLayer){
                query.geometry=point;
                query.fields=["*"];
                let sdQ = await socialDistanceLayer.queryFeatures(query);
                return sdQ;
            }
        }else{
            query.where="county_fips='"+fips+"'";
            query.fields=["*"];
            let sdQ = await socialDistanceLayer.queryFeatures(query);
            return sdQ;

        }
        
    }

    async querySocialDistanceState(state,socialDistanceLayer){
        if(state && socialDistanceLayer){
            let query = socialDistanceLayer.createQuery();
            query.where="state_code='"+state+"'";
            query.fields=["*"]
            query.returnGeometry=false;
            let sdsQ = await socialDistanceLayer.queryFeatures(query);
            return sdsQ;
        }
    }

    async loadOut(){
        let moments = this.state.theData.map(date => moment(date,"YYYY-MM-DD")),maxDate = moment.max(moments);
        let noteText="";
        let cter=0;
        let totalCounties=maxDate._i.counties.length;
        let theText="";
        let avgData=[];
        let sortedAvgData=[];
        let stateAbbrv=this.state.stateAbbrv;
        let previousCases=0;
        let avgCases=0;
        let avgDeath=0;
        let sumCase=0;
        let sumCaseChange=0;
        let sumDeathChange=0;
        let redCross=[];
        let socialDP=[];
        let socialDS=[];
        let redCrossLine;
        let theCode="";
        let nothing=0;
        let theRC=[];
        let socialDistanceLine;
        let socialDistanceData=[];
        let bubblechartDataChilder=[];
        let bubbleRealChart=[];
        let bubblechartData={};
        let maxSort;
        let viewModeSelectDiv=document.getElementById("viewModeSelect");


        if(this.state.mode==="State"){
            viewModeSelectDiv.value="State";
            
            _.forEach(maxDate._i.counties,function(value,key){
                        
                if(totalCounties===1){
                    theText=value.CountyFullName;
                }else if(totalCounties===2){
                    cter+=1;
                    if(totalCounties!==cter){
                        theText+=value.CountyFullName;
                    }else{
                        theText+=" and "+value.CountyFullName+".";
                    }
                }else{
                    cter+=1;
                    if(totalCounties!==cter){
                        theText+=value.CountyFullName+", ";
                    }else{
                        theText+=" and "+value.CountyFullName+".";
                    }
                } 
            });
            _.forEach(config.dataExceptions,function(value,key){
                if(value.state===stateAbbrv){
                    noteText="From The New York Times: "+value.note;
                }
            });
            maxSort = _.orderBy(this.state.theData, ['date'],['desc']);
            _.forEach(maxSort[0].counties,function(value,key){
                bubblechartDataChilder.push({
                    "County":value.Counties_NAME,
                    "Cases":value.cases,
                    "Deaths":value.deaths,
                });
                bubbleRealChart.push({
                    "name": value.Counties_NAME,
                    "value": value.cases
                })
            });
            
            
            _.forEach(this.state.theData,function(value,key){
                avgCases = _.meanBy(value.counties, (c) => c.cases);
                avgDeath = _.meanBy(value.counties, (c) => c.deaths);
                sumCase = _.sumBy(value.counties, 'cases');
                sumCaseChange = _.sumBy(value.counties, 'newCases');
                sumDeathChange = _.sumBy(value.counties, 'newDeaths');

                avgData.push({
                    date:value.date,
                    avgCasePerCounty:avgCases,
                    avgDeathPerCounty:avgDeath,
                    cumulativeTotal:sumCase,
                    caseChange:sumCaseChange,
                    deathChange:sumDeathChange,
                    startdate_converted:value.counties[0].startDate_Converted
                });
                previousCases=sumCase;
            });
            sortedAvgData=_.orderBy(avgData, ['startdate_converted'],['asc']);
            

            try{
                redCross = await this.queryRedCross(this.state.mode,this.state.thePoint,this.state.redCrossLPHE);
            }catch(err){
                console.log(err);
            }
            if(_.get(redCross,"features.length",0)>0){
                theRC=redCross.features[0];
                if(theRC.attributes.local_health_emg==="Yellow"){
                    theCode="Declared Public Health Emergency";
                }else if(theRC.attributes.local_health_emg==="Orange"){
                    theCode="Govt Directed Social Distancing";
                }else if(theRC.attributes.local_health_emg==="Red"){
                    theCode="Govt Ordered Community Quarantine";
                }else{
                    theCode="";
                    nothing=1;
                }
                if(nothing===0){
                    // console.log("HERE")
                    if(theRC.attributes.notes){
                        redCrossLine="Alerts: "+theCode+" - "+theRC.attributes.notes;
                    }else{
                        redCrossLine="Alerts:"+theCode;
                    }
                }
            }else{
                redCrossLine="Could not find Red Cross data";
            }

            try{
                socialDP = await this.querySocialDistancePoint(this.state.mode,this.state.thePoint,this.state.socialDistanceLayer);
            }catch(err){
                console.log(err)
            }
            if(_.get(socialDP,"features.length",0)>0){
                let socialDPFeature=socialDP.features[0].attributes;
                try{
                    socialDS = await this.querySocialDistanceState(socialDPFeature.state_code,this.state.socialDistanceLayer);
                }catch(err){
                    console.log(err)
                }
                if(_.get(socialDS,"features.length",0)>0){
                    let aggrDataSD=_.chain(socialDS.features).groupBy("attributes.state_name").map(function(v, i) {return {state: i,counties: _.map(v, 'attributes')}}).value();
                    _.forEach(aggrDataSD,function(value,key){
                        // console.log(value)
                        var scoreState=value.state;
                        var avgScoreLetter="";
                        var avgDistScoreLetter="";
                        var avgVisitScoreLetter="";
                        var avgTotScore = _.meanBy(value.counties, (c) => c.n_grade_total);
                        var avgDistScore = _.meanBy(value.counties, (c) => c.n_grade_distance);
                        var avgVisitScore = _.meanBy(value.counties, (c) => c.n_grade_visitation);
  
                        // console.log(avgTotScore+" "+avgDistScore+ " "+avgVisitScore)
                        _.forEach(config.layers.socialDistanceGrade.rangeValues,function(v,k){
                            // console.log(v.max+" "+k)
                            var overallScoreCheck=between(avgTotScore,v.min,v.max);
                            var distScoreCheck=between(avgDistScore,v.min,v.max);
                            var visitScoreCheck=between(avgVisitScore,v.min,v.max);
                            if(overallScoreCheck){
                                avgScoreLetter=k;
                                avgScoreLetter=avgScoreLetter.replace("M","-");
                            }
                            if(distScoreCheck){
                                avgDistScoreLetter=k;
                                avgDistScoreLetter=avgDistScoreLetter.replace("M","-");
                            }
                            if(visitScoreCheck){
                                avgVisitScoreLetter=k;
                                avgVisitScoreLetter=avgVisitScoreLetter.replace("M","-");
                            }
                        });
                        socialDistanceLine="Social Distance Score: "+scoreState+"'s overall social score for all counties is an "+avgScoreLetter+" ("+avgTotScore.toFixed(2)+")";
                        socialDistanceData={
                            overall:{
                                letter:avgScoreLetter,
                                score:avgTotScore.toFixed(2)
                            },
                            distance:{
                                letter:avgDistScoreLetter,
                                score:avgDistScore.toFixed(2)
                            },
                            visitation:{
                                letter:avgVisitScoreLetter,
                                score:avgVisitScore.toFixed(2)
                            }
                        }
                    });
                }else{

                }
            }else{

            }
            bubblechartData={
                "name": "root",
                "children": [bubbleRealChart]
            };
            
            this.setState({
                loaded:true,
                thePopupData:{
                    nytNote:noteText,
                    introText:this.state.stateFull+" has had positive COVID-19 cases for "+this.state.theData.length+" days.  The first case(s) was reported on "+moment(maxDate._i.date,"YYYY-MM-DD").format('l')+" in "+theText,
                    redCrossLine:redCrossLine,
                    socialDistance:{
                        line:socialDistanceLine,
                        data:socialDistanceData
                    }
                },
                chartData:sortedAvgData,
                chartDataBubble:bubblechartDataChilder,
                charDataTheRealBubble:bubbleRealChart
            });
        }else if(this.state.mode==="County"){
            viewModeSelectDiv.value="County";
            let cleanCountyName="";
            if(this.state.countyFull.includes("City")){
                cleanCountyName=this.state.countyFull;
            }else{
                cleanCountyName=this.state.countyFull+" County";
            }
            _.forEach(maxDate._i.counties,function(value,key){
                        
                if(totalCounties===1){
                    theText=value.CountyFullName;
                }else if(totalCounties===2){
                    cter+=1;
                    if(totalCounties!==cter){
                        theText+=value.CountyFullName;
                    }else{
                        theText+=" and "+value.CountyFullName+".";
                    }
                }else{
                    cter+=1;
                    if(totalCounties!==cter){
                        theText+=value.CountyFullName+", ";
                    }else{
                        theText+=" and "+value.CountyFullName+".";
                    }
                }
            });
            _.forEach(config.dataExceptions,function(value,key){
                if(value.state===stateAbbrv){
                    noteText="From The New York Times: "+value.note;
                }
            });
            maxSort = _.orderBy(this.state.theData, ['date'],['desc']);
            _.forEach(maxSort[0].counties,function(value,key){
                bubblechartDataChilder.push({
                    "County":value.Counties_NAME,
                    "Cases":value.cases,
                    "Deaths":value.deaths,
                });
                bubbleRealChart.push({
                    "name": value.Counties_NAME,
                    "value": value.cases
                })
            });
            _.forEach(this.state.theData,function(value,key){
                avgCases = _.meanBy(value.counties, (c) => c.cases);
                avgDeath = _.meanBy(value.counties, (c) => c.deaths);
                sumCase = _.sumBy(value.counties, 'cases');
                sumCaseChange = _.sumBy(value.counties, 'newCases');
                sumDeathChange = _.sumBy(value.counties, 'newDeaths');

                avgData.push({
                    date:value.date,
                    avgCasePerCounty:avgCases,
                    avgDeathPerCounty:avgDeath,
                    cumulativeTotal:sumCase,
                    caseChange:sumCaseChange,
                    deathChange:sumDeathChange,
                    startdate_converted:value.counties[0].startDate_Converted
                });
                previousCases=sumCase;
            });
            sortedAvgData=_.orderBy(avgData, ['startdate_converted'],['asc']);
            try{
                redCross = await this.queryRedCross(this.state.mode,this.state.thePoint,this.state.redCrossLPHE);
            }catch(err){
                console.log(err);
            }
            if(_.get(redCross,"features.length",0)>0){
                theRC=redCross.features[0];
                if(theRC.attributes.local_health_emg==="Yellow"){
                    theCode="Declared Public Health Emergency";
                }else if(theRC.attributes.local_health_emg==="Orange"){
                    theCode="Govt Directed Social Distancing";
                }else if(theRC.attributes.local_health_emg==="Red"){
                    theCode="Govt Ordered Community Quarantine";
                }else{
                    theCode="";
                    nothing=1;
                }
                if(nothing===0){
                    // console.log("HERE")
                    if(theRC.attributes.notes){
                        redCrossLine="Alerts: "+theCode+" - "+theRC.attributes.notes;
                    }else{
                        redCrossLine="Alerts:"+theCode;
                    }
                }
            }else{
                redCrossLine="Could not find Red Cross data";
            }

            try{
                socialDP = await this.querySocialDistancePoint(this.state.mode,this.state.thePoint,this.state.socialDistanceLayer);
            }catch(err){
                console.log(err);
            }
            if(_.get(socialDP,"features.length",0)===1){
                let socialData=socialDP.features[0].attributes;
                socialDistanceLine="Social Distance Score: "+cleanCountyName+"'s overall social score is an "+socialData.grade_total+" ("+socialData.n_grade_total.toFixed(2)+")";
            }else{
                //show error
                socialDistanceLine="";
            }
            this.setState({
                loaded:true,
                thePopupData:{
                    nytNote:noteText,
                    introText:cleanCountyName+" has had positive COVID-19 cases for "+this.state.theData.length+" days.  The first case(s) was reported on "+moment(maxDate._i.date,"YYYY-MM-DD").format('l')+".",
                    redCrossLine:redCrossLine,
                    socialDistance:{
                        line:socialDistanceLine,
                        data:socialDistanceData
                        // line:socialDistanceLine,
                        // data:socialDistanceData
                    }
                },
                chartData:sortedAvgData,
                charDataTheRealBubble:bubbleRealChart
            });

        }else if(this.state.mode==="CountyLex"){
            viewModeSelectDiv.value="County";
            let cleanCountyName="";
            if(this.state.countyFull.includes("City")){
                cleanCountyName=this.state.countyFull;
            }else{
                cleanCountyName=this.state.countyFull+" County";
            }
            _.forEach(maxDate._i.counties,function(value,key){
                        
                if(totalCounties===1){
                    theText=value.CountyFullName;
                }else if(totalCounties===2){
                    cter+=1;
                    if(totalCounties!==cter){
                        theText+=value.CountyFullName;
                    }else{
                        theText+=" and "+value.CountyFullName+".";
                    }
                }else{
                    cter+=1;
                    if(totalCounties!==cter){
                        theText+=value.CountyFullName+", ";
                    }else{
                        theText+=" and "+value.CountyFullName+".";
                    }
                }
            });
            _.forEach(config.dataExceptions,function(value,key){
                if(value.state===stateAbbrv){
                    noteText="From The New York Times: "+value.note;
                }
            });
            _.forEach(this.state.theData,function(value,key){
                avgCases = _.meanBy(value.counties, (c) => c.cases);
                avgDeath = _.meanBy(value.counties, (c) => c.deaths);
                sumCase = _.sumBy(value.counties, 'cases');
                sumCaseChange = _.sumBy(value.counties, 'newCases');
                sumDeathChange = _.sumBy(value.counties, 'newDeaths');

                avgData.push({
                    date:value.date,
                    avgCasePerCounty:avgCases,
                    avgDeathPerCounty:avgDeath,
                    cumulativeTotal:sumCase,
                    caseChange:sumCaseChange,
                    deathChange:sumDeathChange,
                    startdate_converted:value.counties[0].startDate_Converted
                });
                previousCases=sumCase;
            });
            sortedAvgData=_.orderBy(avgData, ['startdate_converted'],['asc']);
            try{
                redCross = await this.queryRedCross(this.state.mode,this.state.thePoint,this.state.redCrossLPHE,this.state.countyFips);
            }catch(err){
                console.log(err);
            }
            if(_.get(redCross,"features.length",0)>0){
                theRC=redCross.features[0];
                if(theRC.attributes.local_health_emg==="Yellow"){
                    theCode="Declared Public Health Emergency";
                }else if(theRC.attributes.local_health_emg==="Orange"){
                    theCode="Govt Directed Social Distancing";
                }else if(theRC.attributes.local_health_emg==="Red"){
                    theCode="Govt Ordered Community Quarantine";
                }else{
                    theCode="";
                    nothing=1;
                }
                if(nothing===0){
                    // console.log("HERE")
                    if(theRC.attributes.notes){
                        redCrossLine="Alerts: "+theCode+" - "+theRC.attributes.notes;
                    }else{
                        redCrossLine="Alerts:"+theCode;
                    }
                }
            }else{
                redCrossLine="Could not find Red Cross data";
            }

            try{
                socialDP = await this.querySocialDistancePoint(this.state.mode,this.state.thePoint,this.state.socialDistanceLayer,this.state.countyFips);
            }catch(err){
                console.log(err);
            }
            if(_.get(socialDP,"features.length",0)===1){
                let socialData=socialDP.features[0].attributes;
                socialDistanceLine="Social Distance Score: "+cleanCountyName+"'s overall social score is an "+socialData.grade_total+" ("+socialData.n_grade_total.toFixed(2)+")";
            }else{
                //show error
                socialDistanceLine="";
            }


            this.setState({
                loaded:true,
                thePopupData:{
                    nytNote:noteText,
                    introText:cleanCountyName+" has had positive COVID-19 cases for "+this.state.theData.length+" days.  The first case(s) was reported on "+moment(maxDate._i.date,"YYYY-MM-DD").format('l')+".",
                    redCrossLine:redCrossLine,
                    socialDistance:{
                        line:socialDistanceLine,
                        data:socialDistanceData
                        // line:socialDistanceLine,
                        // data:socialDistanceData
                    }
                },
                chartData:sortedAvgData
            });
        }else if(this.state.mode==="StateLex"){
            viewModeSelectDiv.value="State";
            _.forEach(maxDate._i.counties,function(value,key){
                        
                if(totalCounties===1){
                    theText=value.CountyFullName;
                }else if(totalCounties===2){
                    cter+=1;
                    if(totalCounties!==cter){
                        theText+=value.CountyFullName;
                    }else{
                        theText+=" and "+value.CountyFullName+".";
                    }
                }else{
                    cter+=1;
                    if(totalCounties!==cter){
                        theText+=value.CountyFullName+", ";
                    }else{
                        theText+=" and "+value.CountyFullName+".";
                    }
                } 
            });
            maxSort = _.orderBy(this.state.theData, ['date'],['desc']);
            _.forEach(maxSort[0].counties,function(value,key){
                bubblechartDataChilder.push({
                    "County":value.Counties_NAME,
                    "Cases":value.cases,
                    "Deaths":value.deaths,
                });
                bubbleRealChart.push({
                    "name": value.Counties_NAME,
                    "value": value.cases
                })
            });
            _.forEach(config.dataExceptions,function(value,key){
                if(value.state===stateAbbrv){
                    noteText="From The New York Times: "+value.note;
                }
            });
            _.forEach(this.state.theData,function(value,key){
                avgCases = _.meanBy(value.counties, (c) => c.cases);
                avgDeath = _.meanBy(value.counties, (c) => c.deaths);
                sumCase = _.sumBy(value.counties, 'cases');
                sumCaseChange = _.sumBy(value.counties, 'newCases');
                sumDeathChange = _.sumBy(value.counties, 'newDeaths');

                avgData.push({
                    date:value.date,
                    avgCasePerCounty:avgCases,
                    avgDeathPerCounty:avgDeath,
                    cumulativeTotal:sumCase,
                    caseChange:sumCaseChange,
                    deathChange:sumDeathChange,
                    startdate_converted:value.counties[0].startDate_Converted
                });
                previousCases=sumCase;
            });
            sortedAvgData=_.orderBy(avgData, ['startdate_converted'],['asc']);

            try{
                redCross = await this.queryRedCross(this.state.mode,this.state.thePoint,this.state.redCrossLPHE,"",this.state.stateAbbrv);
            }catch(err){
                console.log(err);
            }
            if(_.get(redCross,"features.length",0)>0){
                theRC=redCross.features[0];
                if(theRC.attributes.local_health_emg==="Yellow"){
                    theCode="Declared Public Health Emergency";
                }else if(theRC.attributes.local_health_emg==="Orange"){
                    theCode="Govt Directed Social Distancing";
                }else if(theRC.attributes.local_health_emg==="Red"){
                    theCode="Govt Ordered Community Quarantine";
                }else{
                    theCode="";
                    nothing=1;
                }
                if(nothing===0){
                    // console.log("HERE")
                    if(theRC.attributes.notes){
                        redCrossLine="Alerts: "+theCode+" - "+theRC.attributes.notes;
                    }else{
                        redCrossLine="Alerts:"+theCode;
                    }
                }
            }else{
                redCrossLine="Could not find Red Cross data";
            }

            try{
                socialDP = await this.querySocialDistancePoint(this.state.mode,this.state.thePoint,this.state.socialDistanceLayer);
            }catch(err){
                console.log(err)
            }
            if(_.get(socialDP,"features.length",0)>0){
                let socialDPFeature=socialDP.features[0].attributes;
                try{
                    socialDS = await this.querySocialDistanceState(socialDPFeature.state_code,this.state.socialDistanceLayer);
                }catch(err){
                    console.log(err)
                }
                if(_.get(socialDS,"features.length",0)>0){
                    let aggrDataSD=_.chain(socialDS.features).groupBy("attributes.state_name").map(function(v, i) {return {state: i,counties: _.map(v, 'attributes')}}).value();
                    _.forEach(aggrDataSD,function(value,key){
                        // console.log(value)
                        var scoreState=value.state;
                        var avgScoreLetter="";
                        var avgDistScoreLetter="";
                        var avgVisitScoreLetter="";
                        var avgTotScore = _.meanBy(value.counties, (c) => c.n_grade_total);
                        var avgDistScore = _.meanBy(value.counties, (c) => c.n_grade_distance);
                        var avgVisitScore = _.meanBy(value.counties, (c) => c.n_grade_visitation);
  
                        // console.log(avgTotScore+" "+avgDistScore+ " "+avgVisitScore)
                        _.forEach(config.layers.socialDistanceGrade.rangeValues,function(v,k){
                            // console.log(v.max+" "+k)
                            var overallScoreCheck=between(avgTotScore,v.min,v.max);
                            var distScoreCheck=between(avgDistScore,v.min,v.max);
                            var visitScoreCheck=between(avgVisitScore,v.min,v.max);
                            if(overallScoreCheck){
                                avgScoreLetter=k;
                                avgScoreLetter=avgScoreLetter.replace("M","-");
                            }
                            if(distScoreCheck){
                                avgDistScoreLetter=k;
                                avgDistScoreLetter=avgDistScoreLetter.replace("M","-");
                            }
                            if(visitScoreCheck){
                                avgVisitScoreLetter=k;
                                avgVisitScoreLetter=avgVisitScoreLetter.replace("M","-");
                            }
                        });
                        socialDistanceLine="Social Distance Score: "+scoreState+"'s overall social score for all counties is an "+avgScoreLetter+" ("+avgTotScore.toFixed(2)+")";
                        socialDistanceData={
                            overall:{
                                letter:avgScoreLetter,
                                score:avgTotScore.toFixed(2)
                            },
                            distance:{
                                letter:avgDistScoreLetter,
                                score:avgDistScore.toFixed(2)
                            },
                            visitation:{
                                letter:avgVisitScoreLetter,
                                score:avgVisitScore.toFixed(2)
                            }
                        }
                    });
                }else{

                }
            }else{

            }
            this.setState({
                loaded:true,
                thePopupData:{
                    nytNote:noteText,
                    introText:this.state.stateFull+" has had positive COVID-19 cases for "+this.state.theData.length+" days.  The first case(s) was reported on "+moment(maxDate._i.date,"YYYY-MM-DD").format('l')+" in "+theText,
                    redCrossLine:redCrossLine,
                    socialDistance:{
                        line:socialDistanceLine,
                        data:socialDistanceData
                    }
                },
                chartData:sortedAvgData,
                charDataTheRealBubble:bubbleRealChart
            });
        }else{

        }
    }

    componentDidMount(){
        this.loadOut();
    }

    render() {
        if(this.state.loaded===false){
            return(
                <>
                    <div style={{textAlign:"center"}}>
                        <Spinner animation="border" variant="light" />
                    </div>
                </>
            )
        }else{
           

            return(
                <>
                    <div style={{width:"100%"}}>
                        <p>{this.state.thePopupData.redCrossLine}</p>
                        <p>{this.state.thePopupData.introText}</p>
                        <p>{this.state.thePopupData.socialDistance.line}</p>
                        <p>{this.state.thePopupData.nytNote}</p>
                    </div>
                    <div style={{height:"300px",width:"100%"}}>
                        <TheChartDiv root={this.state.chartDataBubble} root2={this.state.charDataTheRealBubble}></TheChartDiv>
                    </div>
                </>
            )
        }
    }
}

export default MapPopUpDiv;