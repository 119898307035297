import React, { Component } from 'react';
import { Spin,List,Space } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { caseMax,newcasesMax,theFlag,deathCount,newDeathCount } from '../../../Resources/functions/Functions';
import { MessageOutlined, LikeOutlined, StarOutlined } from '@ant-design/icons';

const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

class DetailList extends Component {
    constructor(props) {
      super(props);
      this.state = {
            active:false,
            loading:true,
            mode:this.props.mode, 
      };
    }

    async updateChart(){
        var dateData=[];
        var deathData=[];
        var deathNewData=[];
        const listData = [];
        var mapData = this.props.theProps.viewData;
        var data = this.props.data;

        if(_.get(mapData.timeSlider.timeExtent,"start",false)!==false){
            var statQuery = mapData.layerCases.createQuery();
            if(this.props.mode==="us"){
                statQuery.where="startdate_converted=date'" + moment(mapData.timeSlider.timeExtent.start.getTime()).format('YYYY-MM-DD')+"'";
            }else if(this.props.mode==="st"){
                statQuery.where="startdate_converted=date'" + moment(mapData.timeSlider.timeExtent.start.getTime()).format('YYYY-MM-DD')+"'";
            }else if(this.props.mode==="co"){
                statQuery.where="startdate_converted=date'" + moment(mapData.timeSlider.timeExtent.start.getTime()).format('YYYY-MM-DD')+"'";
            }else{
                // todo error
            }
            statQuery.outStatistics = [
                caseMax,
                newcasesMax,
                deathCount,
                newDeathCount,
                theFlag
            ];
            statQuery.geometry=mapData.view.extent;
            statQuery.orderByFields=["Max_newcases DESC"];
            statQuery.outFields=["countyfullname","st_abbrev"];
            statQuery.groupByFieldsForStatistics=["countyfullname","st_abbrev"];
            statQuery.returnGeometry=false;
            var sq = await mapData.layerCases.queryFeatures(statQuery);
            if(_.get(sq,"features.length",0)>0){
                _.forEach(sq.features,function(value,key){
                    listData.push({
                        // href: 'https://ant.design',
                        title: value.attributes.countyfullname+", "+value.attributes.st_abbrev,
                        description:value.attributes.Max_newcases+' ('+value.attributes.Max_cases+' overall) new cases and '+value.attributes.newDeathCount+' ('+value.attributes.deathCount+' overall) new deaths',
                        // content:value.attributes.caseCount+' ('+value.attributes.newcasesCount+' new) overall cases and '+value.attributes.newDeathCount+' ('+value.attributes.newDeathCount+' new) overall deaths',
                    });
                    
                });
                
                this.setState({
                    listData:listData,
                    loading:false,
                    error:false,
                    active:true,
                    
                });
            }else{
                this.setState({
                    active:false,
                    loading:false
                });
            }

        }else{
            // no data or error
            console.log("No data");
            this.setState({
                active:false,
                loading:false
            });
        }
    }

    componentDidMount(){
        console.log("Details List Mounted");
        this.updateChart();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.theProps.mapState.dateRange!==this.props.theProps.mapState.dateRange){
            console.log("Details List: Date Change");
            this.updateChart();
        }
        if(this.props.theProps.viewData.x!==prevProps.theProps.viewData.x || this.props.theProps.viewData.x!==prevProps.theProps.viewData.x){
            console.log("Details List: Map Moved x,y");
            this.updateChart();
        }
    }

    render() {

        if(this.state.active===true && this.state.loading===false){
            return (
                <>
                    <div className="resultPane">
                        <List
                            itemLayout="vertical"
                            size="small"
                            pagination={{
                                onChange: page => {
                                    console.log(page);
                                },
                                pageSize: 6,
                                position:"bottom",
                                simple:true
                            }}
                            dataSource={this.state.listData}
                            footer={
                                <div>
                                    <i>Sorted by new cases</i>
                                </div>
                            }
                            style={{
                                color:"white",
                                padding:"5px"
                            }}
                            renderItem={item => (
                                <List.Item
                                    key={item.title}
                                    // actions={[
                                    //     <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
                                    //     <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
                                    //     <IconText icon={MessageOutlined} text="2" key="list-vertical-message" />,
                                    // ]}
                                    // extra={
                                    // <img
                                    //     width={272}
                                    //     alt="logo"
                                    //     src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                                    // />
                                    // }
                                >
                                <List.Item.Meta
                                    // avatar={<Avatar src={item.avatar} />}
                                    title={<a href={item.href}>{item.title}</a>}
                                    description={item.description}
                                />
                                    {item.content}
                                </List.Item>
                            )}
                        />
                    </div>
                </>
            );
        }else if(this.state.active===true && this.state.loading===true){
            return (
                <><Spin size="large" /></>
            )
        }else if(this.state.active===true && this.state.loading===true){
            return(
                <>
                    <div className="loadCenter">
                        <Spin />
                    </div>
                </>

            )
        }else{
            return (
                <>
                    <div>
                        <p>No Data</p>
                    </div>
                </>
            );
        }
    }
}

export default DetailList;