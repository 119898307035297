import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import MapAppPortalDev from '../src/Components/Map/TheMapPortalDev';
import 'antd/dist/antd.css';

const App = () => {
  return (
      <HashRouter basename="/">
            <Switch>
              <Route exact path="/" component={MapAppPortalDev}/>
           </Switch>
      </HashRouter>
  );
}
export default App;
