import React, { Component } from 'react';
import _ from 'lodash';
import { Spin } from 'antd';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { caseCount,newcasesCount,deathCount,newDeathCount } from '../../../Resources/functions/Functions';

class ApexChartChange extends Component {
    constructor(props) {
      super(props);
      this.state = {
            active:false,
            loading:true,
            series: [],
            options: {
                chart: {
                    type: 'line',
                    height: "100%",
                    stacked: false,
                    foreColor: '#FFF',
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 400,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 50
                        }
                    }
                },
                colors: ['#CF4917', '#31778E'],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        barHeight: '80%',
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 2,
                    curve: 'smooth',
                    colors: ['#CF4917', '#31778E']
                },
                
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                yaxis: [
                    {
                        seriesName: 'Case Change',
                        show:false,
                        opposite: false,
                        axisTicks: {
                          show: true,
                        },
                        axisBorder: {
                          show: false,
                        //   color: '#00E396'
                        },
                        labels: {
                            show:false,
                            style: {
                                colors: '#00E396',
                            }
                        },
                        title: {
                          text: "Case Change",
                        //   style: {
                        //     color: '#00E396',
                        //   }
                        },
                    },
                    {
                        seriesName: 'Death Change',
                        show:false,
                        opposite: true,
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: false,
                        //   color: '#00E396'
                        },
                        labels: {
                            show:false,
                            style: {
                                colors: '#00E396',
                            }
                        },
                        title: {
                            text: "Death Change",
                        //   style: {
                        //     color: '#00E396',
                        //   }
                        },
                    },
                ],
                tooltip: {
                    shared: true,
                    theme: "dark",
                    // x: {
                    //     formatter: function (val) {
                    //         return val
                    //     }
                    // },
                    y: {
                        formatter: function (val) {
                            return Math.abs(val)+"%";
                        }
                    }
                },
                // title: {
                //     text: 'This will be a chart'
                // },
                xaxis: {
                    type: 'datetime',
                    categories: [],
                    labels: {
                        show:true,
                        datetimeFormatter: {
                            year: 'yyyy',
                            month: 'MMM dd',
                            day: 'MMM dd',
                        }
                    }
                },
            },
      };
    }

    async updateChart(){
        let dateData=[];
        let inQ="";
        let caseData=[];
        let deathData=[];
        let caseNewData=[];
        var mapData = this.props.theProps.viewData;

        if(_.get(mapData.timeSlider.timeExtent,"end",false)!==false){
            _.forEach(this.props.theProps.mapState.fipsArray,function(value,key){
                inQ+="'"+value+"',";
            });
            inQ=inQ.slice(0, -1); 
            var statQuery = mapData.layerCases.createQuery();
            if(this.props.theProps.mode==="us"){
                statQuery.where="flag=1 AND startdate_converted<=date'" + moment(mapData.timeSlider.timeExtent.start.getTime()).format('l')+"' AND fipsgj IN("+inQ+")";
            }else if(this.props.theProps.mode==="st"){
                statQuery.where="flag=1 AND startdate_converted<=date'" + moment(mapData.timeSlider.timeExtent.start.getTime()).format('l')+"' AND fipsgj IN("+inQ+")";
            }else if(this.props.theProps.mode==="co"){
                statQuery.where="flag=1 AND startdate_converted<=date'" + moment(mapData.timeSlider.timeExtent.start.getTime()).format('l')+"' AND fipsgj IN("+inQ+")";
            }else{
                // todo error
            }
            statQuery.outStatistics = [
                caseCount,
                newcasesCount,
                deathCount,
                newDeathCount
            ];
            // statQuery.geometry=mapData.view.extent;
            statQuery.orderByFields=["date"];
            statQuery.groupByFieldsForStatistics=["date"];
            statQuery.returnGeometry=false;
            var sq = await mapData.layerCases.queryFeatures(statQuery);
            if(_.get(sq,"features.length",0)>0){
                _.forEach(sq.features,function(value,key){
                    let pastCase=value.attributes.caseCount-value.attributes.newcasesCount;
                    let pastDeath=value.attributes.deathCount-value.attributes.newDeathCount;
                    let caseChange=0;
                    let deathChange=0;
                    // (New Value - Initial Value)/(Initial Value) * 100
                    if(pastCase>0){
                        caseChange = ((value.attributes.caseCount - pastCase)/pastCase)*100;
                        caseChange = caseChange.toFixed(3);
                    }
                    if(pastDeath>0){
                        deathChange = ((value.attributes.deathCount - pastDeath)/pastDeath)*100;
                        deathChange = deathChange.toFixed(3)
                    }
    
                    dateData.push((new Date(value.attributes.date)).getTime());
                    caseData.push(caseChange);
                    deathData.push(deathChange);
    
                });
                
                this.setState({
                    series: [
                        {
                            name: 'Case Change',
                            type: 'line',
                            data: caseData
                        },
                        {
                            name: 'Death Change',
                            type:'line',
                            data:deathData
                        }
                        
                    ],
                    options: {
                      ...this.state.options,
                      
                      xaxis: {
                        ...this.state.options.xaxis,
                        categories:dateData
                      }
                    },
                    loading:false,
                    error:false,
                    active:true,
                    details:{
    
                    }
                });
            }else{
                // no data or error
                console.log("No data");
                this.setState({
                    active:false,
                    loading:false
                });
            }

        }else{
            this.setState({
                active:false,
                loading:false
            });
        }
    }

    componentDidMount(){
        console.log("Change Chart: Mounted");
        this.updateChart();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.theProps.mapState.dateRange!==this.props.theProps.mapState.dateRange){
            console.log("Change Chart: Time Updated");
            this.updateChart();
        }
        if(prevProps.theProps.mode!==this.props.theProps.mode){
            console.log("Change Chart: Mode Updated");
            this.updateChart();
        }
        if(this.props.theProps.viewData.x!==prevProps.theProps.viewData.x || this.props.theProps.viewData.x!==prevProps.theProps.viewData.x){
            console.log("Change Chart: Map Moved x,y");
            this.updateChart();
        }
    }

    render() {
        if(this.state.active===true && this.state.loading===false){
            return (
                <>
                    <div id="chart" className="resultPane">
                        <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={"100%"} />
                    </div>
                </>
            );
        }else if(this.state.active===true && this.state.loading===true){
            return (
                <><Spin size="large" /></>
            )
        }else if(this.state.active===false && this.state.loading===true){
            return(
                <>
                    <div className="loadCenter">
                        <Spin />
                    </div>
                </>

            )
        }else{
            return (
                <>
                    <div>
                        <p>No Data</p>
                    </div>
                </>
            );
        }
    }
}

export default ApexChartChange;