import React, { Component,render, useState } from 'react';
import ReactDOM from 'react-dom';
import { loadModules } from 'esri-loader';
import moment from 'moment';
import _ from 'lodash';
import config from '../../config';
import TheChatBot from '../Chat';
import qs from 'query-string';
// import { ChatBot } from 'aws-amplify';
// import { ChatBot,AmplifyTheme  } from 'aws-amplify-react';
// import { Interactions } from 'aws-amplify';
// import { ChatFeed, Message } from 'react-chat-ui';
import '../../Resources/css/theMap.css';
import { abbreviate,caseMax,newcasesMax,newcasesMin,deathMax,pop19Max,newDeathMax,per100kMax,per100kMin,maxObjectID,maxfips,toTitleCase } from '../../Resources/functions/Functions'; 
import { Layout, Menu,Card, Statistic, Row, Col, Button,Typography,Spin,Tabs,PageHeader,Descriptions,Dropdown,Select } from 'antd';
import { FilterOutlined,MenuUnfoldOutlined,MenuFoldOutlined,DownOutlined,ArrowLeftOutlined,ArrowRightOutlined   } from '@ant-design/icons';
import ResultsContainer from '../Popup/ResultContainer';

const clusterConfig = {
    type: "cluster",
    clusterRadius: "100px",
    clusterMinSize: "24px",
    clusterMaxSize: "64px",
    // {cluster_count} is an aggregate field containing
    // the number of features comprised by the cluster
    popupTemplate: {
        content: [
            {
                type: "text",
                text: "This cluster represents {cluster_count} counties averaging {cluster_avg_newcases} COVID-19 cases."
            }
    ],
    fieldInfos: [
        {
            fieldName: "cluster_count",
            format: {
                places: 0,
                digitSeparator: true
            }
        },
        {
            fieldName: "cluster_avg_newcases",
            label: "Average New Cases",
            format: {
                places: 0
            }
        },
    ]},
    
    labelsVisible:true,
    labelingInfo: [
      {
        deconflictionStrategy: "none",
        labelExpressionInfo: {
          expression: "Text($feature.cluster_avg_cases, '#,###')"
        },
        symbol: {
          type: "text",
          color: "#004a5d",
          font: {
            weight: "bold",
            family: "Noto Sans",
            size: "12px"
          }
        },
        labelPlacement: "center-center"
      }
    ]
};

const { Option } = Select;
const { TabPane } = Tabs;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Title,Text } = Typography;
const opts = {
    duration: 1000
};

localStorage.clear();

class MapAppKoopDev extends Component {
      constructor(props){
        super(props)
        this.state={
            activeFilter:"us",
            smartMap:false,
            mode:"us",
            rederer:"Default",
            dataset:"Historic",
            refreshTime:false,
            modeSelect:null,
            resultsActive:"hide",
            firstLoad:true,
            mapLoading:true,
            countySpinning:true,
            stateSpinning:true,
            stateSelection:"State",
            countySelection:"County",
            viewHandles:[],
            viewData:{},
            mapState:{
                dateRange:"Loading...",
                totalCases:0,
                totalNewCases:0,
                totalDeaths:0,
                totalNewDeaths:0,
                totalPop:0,
                maxPer100kNew:0,
                minPer100kNew:0,
                maxNewCases:0,
                minNewCases:0,
                per100kTotalnew:0,
                casePercentChangeNew:0,
                deathPercentNew:0,
                deathChangePercentNew:0
            }
        };
        this.lexViewModeChange = this.lexViewModeChange.bind(this);
        this.modeSwitch = this.modeSwitch.bind(this);
        this.rendererSwitch = this.rendererSwitch.bind(this);
        this.datasetSwitch = this.datasetSwitch.bind(this);
        this.openLex = this.openLex.bind(this);
        this.modeSwitchFinal = this.modeSwitchFinal.bind(this);
    }

    modeSwitchFinal(type,result,theView){
        if(_.get(result,"success",false)!==false){
            if(type==="st"){
                this.setState({
                    modeSelect:result.modeSelect,
                    stateSpinning:false,
                    stateSelection:"State: "+result.selectionText
                });
                theView.goTo(result.geometry.extent);

            }
            if(type==="co"){
                this.setState({
                    modeSelect:result.modeSelect,
                    countySpinning:false,
                    countySelection:"County: "+result.selectionText
                });
                theView.goTo(result.geometry.extent);
            }
        }else{
            // note - error
        }
    }

    modeSwitch(item){
        // note - clean up states with each click
        let resultClick;

        this.setState({
            activeFilter:item.key,
            mode:item.key,
            stateSpinning:false,
            stateSelection:"State",
            countySpinning:false,
            countySelection:"County"
        });

        async function qLayer(lyr,point){
            if(_.get(point,"type",false)==="point"){
                let q = await lyr.queryFeatures({
                    geometry: point,
                    spatialRelationship: "intersects",
                    returnGeometry: true,
                    outFields: ["*"]
                });
                console.log(q.features)
                if(_.get(q.features,"length",0)===1){
                    return {
                        success:true,
                        feature:q.features[0].attributes,
                        geometry:q.features[0].geometry
                    };
                }else{
                    // note - we only want one selection\
                    return {
                        success:false
                    };
                }
            }else{
                // note - error from no point gathered??
                return {
                    success:false
                };
            }
        }

        if(item.key!=="us"){
            let that=this;
            let lyr;
            let theView=this.state.viewData.view;
            if(item.key==="st"){
                lyr=this.state.viewData.stateLayer;
                this.setState({
                    stateSelection:"Please select a state...",
                    stateSpinning:true
                });
            }else if(item.key==="co"){
                lyr=this.state.viewData.countyLayer;
                this.setState({
                    countySelection:"Please select a county...",
                    countySpinning:true
                });
            }else{
                // err
            }
            let rootClick=this.state.viewData.view.on("click",function(event){
                console.log("layer Click");
                if(item.key==="st"){
                    that.setState({
                        stateSelection:"Finding state data..." 
                    });

                }
                if(item.key==="co"){
                    that.setState({
                        countySelection:"Finding county data..." 
                    });
                }
                
                var point=theView.toMap(event);
                qLayer(lyr,point).then(function(results){
                    if(_.get(results,"success",false)!==false){
                        if(item.key==="st"){
                            resultClick={
                                success:true,
                                modeSelect:results.feature.st_abbrev,
                                selectionText:results.feature.state,
                                geometry:results.geometry
                            };
                            that.modeSwitchFinal(item.key,resultClick,theView);
                            
                        }
                        if(item.key==="co"){
                            resultClick={
                                success:true,
                                modeSelect:results.feature.fips,
                                selectionText:results.feature.name+", "+results.feature.state,
                                geometry:results.geometry
                            };
                            that.modeSwitchFinal(item.key,resultClick,theView);
                        }
                    }else{
                        // note - error on layer query
                    }
                });
                
                rootClick.remove();
            });
        }else{
            // note - the selection is us so go back to default settings
            this.setState({
                modeSelect:null
            });
        }
    }

    rendererSwitch(item){
        this.setState({
            rederer:item
        });
    }

    datasetSwitch(item,viewData){
        this.state.viewData.map.removeMany([this.state.viewData.layerCases,this.state.viewData.stateLayer,this.state.viewData.countyLayer]);
        // remove the watch handles
        console.log(this.state.viewHandles);
        _.forEach(this.state.viewHandles,function(handle){
            handle.remove();
        })
        this.setState({
            dataset:item,
            refreshTime:true
        });
        // this.loadTheMap();
    }

    lexViewModeChange(){
        console.log("Lex changed the view mode")
    }

    toggle = () => {
        this.setState({
          collapsed: !this.state.collapsed,
        });
    };

    openLex(){
        if(this.state.smartMap===false){
            console.log("Show Lex");
            const node = document.createElement("div");
            this.state.viewData.view.ui.add(node,"top-right");
            ReactDOM.render(<TheChatBot that={this} mode={this.state.mode} modeSelect={this.state.modeSelect} mapState={this.state.mapState} viewData={this.state.viewData}></TheChatBot>,node);
            this.setState({
                smartMap:true,
                smartMapNode:node
            });
        }else{
            console.log("Remove Lex")
            ReactDOM.unmountComponentAtNode(this.state.smartMapNode)
            this.setState({
                smartMap:false
            });
        }
    }

    async loadTheMap(){
        const options = {
            version: '4.16',
            css: true
        };

        try {
            const values = qs.parse(this.props.location.search);
            let timeSlider;
            let timeWatch;
            let mapWatch;
            let viewHandles = [];

            const [ Map,MapView,FeatureLayer,watchUtils,TimeSlider ] = await loadModules([
                'esri/Map',
                'esri/views/MapView',
                'esri/layers/FeatureLayer',
                'esri/core/watchUtils',
                'esri/widgets/TimeSlider'], options);

            // note - check what dataset we are dealing with for cases layer load
            let layerUrl;
            if(this.state.dataset==="Historic"){
                layerUrl=config.layers.cases.url1;
            }else{
                layerUrl=config.layers.cases.url2;
            }

            var layerCases = new FeatureLayer({
                url:layerUrl,
                copyright: config.layers.cases.copyright,
                title: config.layers.cases.title,
                visible:false,
                timeInfo: {
                    startField: "date", // name of the date field
                    interval: {
                        unit: "days",
                        value: 1
                    }
            },
                // featureReduction: clusterConfig,

            });

            var stateLayer = new FeatureLayer({
                url:config.layers.states.url,
            });

            var countyLayer = new FeatureLayer({
                url:config.layers.counties.url
            });

            let map = new Map({
                basemap: {
                    portalItem: {
                      id: "75a3ce8990674a5ebd5b9ab66bdab893"
                    }
                },
                layers: [layerCases]
            });

            let view = new MapView({
                map: map,
                container: "viewDiv",
                //4
                zoom: 7,
                center: [config.map.center.long,config.map.center.lat],
                constraints: {
                    minScale: 18489300,
                    maxScale: 577790
                  }
            });

            if(this.state.refreshTime===false){
                timeSlider = new TimeSlider({
                    container: "timeSlider",
                    mode:"instant",
                    playRate: 1000,
                    // 650 is OG
                    // mode:"time-window",
                    stops: {
                        interval: {
                            value: 1,
                            unit: "days"
                        }
                    }
                });
    
                view.ui.add(timeSlider, "timeSlider");
            }else if(this.state.refreshTime===true){
                timeSlider=this.state.viewData.timeSlider;
            }

            async function whenDone() {
                const layerView = await view.whenLayerView(layerCases);
                await watchUtils.whenFalseOnce(layerView, 'updating');
                console.log('layerView is done loading and drawing');
                return {
                    success:true,
                    layerView:layerView
                };
            }

            let casesView = await whenDone();

            async function statGrab(from,time,theView,mode,modeSelect){
                let returnData;
                let deathPColor="";
                let per100kTotalnew=0;
                let deathChangePercentNew=0;
                let casePercentChangeNew=0;
                let deathPercentNew=0;
                let totalCases=0;
                let totalNewCases=0;
                let totalDeaths=0;
                let totalNewDeaths=0;
                let totalPop=0;
                let maxPer100kNew;
                let minPer100kNew;
                let maxNewCases;
                let minNewCases;
                let fipsArray=[];
                console.log('Stat Update:',from);
                // console.log('x: ',theView.center.x," y:",theView.center.y);
                // console.log(time.timeExtent.start);
                console.log(mode,modeSelect);
                let q="startdate_converted=date'" + moment(time.timeExtent.start.getTime()).format('YYYY-MM-DD')+"'";
                if(mode==="st" && !!modeSelect){
                    q="startdate_converted=date'" + moment(time.timeExtent.start.getTime()).format('YYYY-MM-DD')+"' AND st_abbrev='"+modeSelect+"'";
                }
                if(mode==="co" && !!modeSelect){
                    q="startdate_converted=date'" + moment(time.timeExtent.start.getTime()).format('YYYY-MM-DD')+"' AND fipsgj='"+modeSelect+"'";
                }
                // console.log('q=',q);
                const theEffect={
                    filter: {
                        where:q,
                        geometry: theView.extent
                    },
                    excludedEffect: config.layers.cases.excludeEffects,
                    includedEffect: config.layers.cases.includedEffects
                };
                layerCases.definitionExpression=q;
                // note - not 100% sure if i need the effect anymore with the wormum dots
                casesView.layerView.effect = theEffect;
                // note - layerCases is defaulting to visible=false
                if(layerCases.visible===false){
                    layerCases.visible=true;
                }

                // STATS //
                const statQuery = casesView.layerView.effect.filter.createQuery();
                statQuery.where=q;
                statQuery.groupByFieldsForStatistics=["groupstatecounty"];
                statQuery.outStatistics = [
                    caseMax,
                    newcasesMax,
                    newcasesMin,
                    deathMax,
                    pop19Max,
                    newDeathMax,
                    per100kMax,
                    per100kMin,
                    maxObjectID,
                    maxfips
                ];
                let result = await layerCases.queryFeatures(statQuery);
                if(_.get(result.features,"length",0)>0){
                    
                    totalCases = _.sumBy(result.features, 'attributes.Max_cases');
                    totalNewCases = _.sumBy(result.features,'attributes.Max_newcases');
                    totalDeaths = _.sumBy(result.features, 'attributes.maxDeaths');
                    totalNewDeaths = _.sumBy(result.features,'attributes.newDeathMax');
                    totalPop = _.sumBy(result.features, 'attributes.pop19Max');
                    maxPer100kNew = _.maxBy(result.features,'attributes.Max_per100k');
                    minPer100kNew = _.minBy(result.features,'attributes.Min_per100k');
                    maxNewCases = _.maxBy(result.features,'attributes.Max_newcases');
                    minNewCases = _.minBy(result.features,'attributes.Max_newcases');
                    maxPer100kNew=maxPer100kNew.attributes.Max_per100k;
                    minPer100kNew=minPer100kNew.attributes.Min_per100k;
                    maxNewCases=maxNewCases.attributes.Max_newcases;
                    minNewCases=minNewCases.attributes.Max_newcases;
                    _.forEach(result.features,function(value,key){
                        fipsArray.push(value.attributes.maxfips);
                    });
                    if(totalCases>0){
                        per100kTotalnew = ((totalCases/totalPop)*100000).toFixed(2);
                        casePercentChangeNew = ((totalNewCases/(totalCases-totalNewCases))*100).toFixed(2);
                        deathPercentNew = ((totalDeaths/totalCases)*100).toFixed(2);
                    }
        
                    if(totalDeaths>0){
                        deathChangePercentNew = ((totalNewDeaths/(totalDeaths-totalNewDeaths))*100).toFixed(2);
                    }
                }else{
        
                }

                returnData={
                    success:true,
                    stats:{
                        time:time.timeExtent.start.toLocaleDateString(),
                        totalCases:totalCases,
                        totalNewCases:totalNewCases,
                        totalDeaths:totalDeaths,
                        totalNewDeaths:totalNewDeaths,
                        totalPop:totalPop,
                        maxPer100kNew:maxPer100kNew,
                        minPer100kNew:minPer100kNew,
                        maxNewCases:maxNewCases,
                        minNewCases:minNewCases,
                        per100kTotalnew:per100kTotalnew,
                        casePercentChangeNew:casePercentChangeNew,
                        deathPercentNew:deathPercentNew,
                        deathChangePercentNew:deathChangePercentNew,
                        fipsArray:fipsArray
                    }
                };
                
                return returnData;
            }

            function buildSymbology(max,min,rederer){
                // var maxCases = `+maxNewCases+`;
                // var minCases = `+minNewCases+`;
                if(rederer==="Default"){
                    layerCases.renderer = {
                        type: "simple",
                        symbol: {
                          type: "cim",
                          data: {
                            type: "CIMSymbolReference",
                            symbol: {
                              type: "CIMPointSymbol",
                              symbolLayers: [
                                {
                                  type: "CIMVectorMarker",
                                  enable: true,
                                  anchorPoint: { x: 0, y: 0 },
                                  anchorPointUnits: "Relative",
                                  primitiveName: "innerSizeOverride",
                                  frame: { xmin: 0.0, ymin: 0.0, xmax: 17.0, ymax: 17.0 },
                                  markerGraphics: [
                                    {
                                      type: "CIMMarkerGraphic",
                                      geometry: {
                                        rings: [
                                          [
                                            [8.5, 0.2],
                                            [7.06, 0.33],
                                            [5.66, 0.7],
                                            [4.35, 1.31],
                                            [3.16, 2.14],
                                            [2.14, 3.16],
                                            [1.31, 4.35],
                                            [0.7, 5.66],
                                            [0.33, 7.06],
                                            [0.2, 8.5],
                                            [0.33, 9.94],
                                            [0.7, 11.34],
                                            [1.31, 12.65],
                                            [2.14, 13.84],
                                            [3.16, 14.86],
                                            [4.35, 15.69],
                                            [5.66, 16.3],
                                            [7.06, 16.67],
                                            [8.5, 16.8],
                                            [9.94, 16.67],
                                            [11.34, 16.3],
                                            [12.65, 15.69],
                                            [13.84, 14.86],
                                            [14.86, 13.84],
                                            [15.69, 12.65],
                                            [16.3, 11.34],
                                            [16.67, 9.94],
                                            [16.8, 8.5],
                                            [16.67, 7.06],
                                            [16.3, 5.66],
                                            [15.69, 4.35],
                                            [14.86, 3.16],
                                            [13.84, 2.14],
                                            [12.65, 1.31],
                                            [11.34, 0.7],
                                            [9.94, 0.33],
                                            [8.5, 0.2]
                                          ]
                                        ]
                                      },
                                      symbol: {
                                        type: "CIMPolygonSymbol",
                                        symbolLayers: [
                                          {
                                            type: "CIMSolidFill",
                                            enable: true,
                                            color: [0, 0, 0, 30]
                                          }
                                        ]
                                      }
                                    }
                                  ],
                                  scaleSymbolsProportionally: true,
                                  respectFrame: true
                                },
                                {
                                  type: "CIMVectorMarker",
                                  enable: true,
                                  anchorPoint: { x: 0, y: 0 },
                                  anchorPointUnits: "Relative",
                                  primitiveName: "outerSizeOverride",
                                  frame: { xmin: 0.0, ymin: 0.0, xmax: 17.0, ymax: 17.0 },
                                  markerGraphics: [
                                    {
                                      type: "CIMMarkerGraphic",
                                      geometry: {
                                        rings: [
                                          [
                                            [8.5, 0.2],
                                            [7.06, 0.33],
                                            [5.66, 0.7],
                                            [4.35, 1.31],
                                            [3.16, 2.14],
                                            [2.14, 3.16],
                                            [1.31, 4.35],
                                            [0.7, 5.66],
                                            [0.33, 7.06],
                                            [0.2, 8.5],
                                            [0.33, 9.94],
                                            [0.7, 11.34],
                                            [1.31, 12.65],
                                            [2.14, 13.84],
                                            [3.16, 14.86],
                                            [4.35, 15.69],
                                            [5.66, 16.3],
                                            [7.06, 16.67],
                                            [8.5, 16.8],
                                            [9.94, 16.67],
                                            [11.34, 16.3],
                                            [12.65, 15.69],
                                            [13.84, 14.86],
                                            [14.86, 13.84],
                                            [15.69, 12.65],
                                            [16.3, 11.34],
                                            [16.67, 9.94],
                                            [16.8, 8.5],
                                            [16.67, 7.06],
                                            [16.3, 5.66],
                                            [15.69, 4.35],
                                            [14.86, 3.16],
                                            [13.84, 2.14],
                                            [12.65, 1.31],
                                            [11.34, 0.7],
                                            [9.94, 0.33],
                                            [8.5, 0.2]
                                          ]
                                        ]
                                      },
                                      symbol: {
                                        type: "CIMLineSymbol",
                                        symbolLayers: [
                                          {
                                            type: "CIMSolidStroke",
                                            enable: true,
                                            color: [150, 150, 150, 100],
                                            width: 1
                                          }
                                        ]
                                      }
                                    }
                                  ],
                                  scaleSymbolsProportionally: true,
                                  respectFrame: true
                                }
                              ]
                            },
                            primitiveOverrides: [
                              {
                                type: "CIMPrimitiveOverride",
                                primitiveName: "outerSizeOverride",
                                propertyName: "Size",
                                valueExpressionInfo: {
                                  type: "CIMExpressionInfo",
                                  title: "Size in pixels of outer ring at maxScale",
                                  // the pixel size at the largest scale
                                  // 120 represents the pixel size of the
                                  // circles at the view's largest scale (1:2,311,161)
                                  expression: "70 * 577790 / $view.scale",
                                  returnType: "Default"
                                }
                              },
                              {
                                type: "CIMPrimitiveOverride",
                                primitiveName: "innerSizeOverride",
                                propertyName: "Size",
                                valueExpressionInfo: {
                                  type: "CIMExpressionInfo",
                                  title: "Size in pixels of inner ring at maxScale",
                                  // Testing - Changing to max min new cases
                                  // var maxCases = `+maxPer100kNew+`;
                                  // var minCases = `+minPer100kNew+`;
      
                                  expression: `
                                    var maxCases = `+max+`;
                                    var minCases = `+min+`;
                                    var maxScale=68;
                                    var minScale=5;
                                    var casesRatio = ((maxScale - minScale) * ($feature.newcases - minCases) / (maxCases - minCases) + minScale);
                                    
                                    casesRatio = Round(casesRatio,1)/100;
                                    var outerSize = 70 * 577790 / $view.scale;
                                    var innerSize = outerSize * casesRatio;
                                    innerSize = IIF( casesRatio > 1, 68 * 577790 / $view.scale, innerSize );
                                    
                                    return IIF( innerSize < 3, 5, Round(innerSize,1) );
                                    
                                  `,
                                  returnType: "Default"
                                //   return IIF( innerSize < .4, 3, innerSize );
                                }
                              }
                            ]
                          }
                        },
                        visualVariables: [
                          {
                            type: "color",
                            field: "cases",
                            stops: [
                              {
                                value: 153,
                                color: "#01204F"
                              },
                              {
                                value: 413,
                                color: "#016D93"
                              },
                              {
                                value: 797,
                                color: "#008994"
                              },
                              {
                                value: 1352,
                                color: "#6FADA2"
                              },
                              {
                                value: 2117,
                                color: "#F7DBAC"
                              },
                              {
                                  value: 3490,
                                  color: "#FCAB69"
                              },
                              {
                                value: 3734,
                                color: "#FE6C31"
                            },
                            {
                                value: 15000,
                                color: "#FA3314"
                            }
                            ]
                          }
                        ]
                    };
                }else if(rederer==="Cluster"){
                    layerCases.featureReduction=clusterConfig;
                }
                
            }

            const mapMoveHandler = () => {
                // note - map move hits first
                if(this.state.firstLoad===true){
                    // dont do any stat q but set first load to false
                    console.log("Map moved - First Load")
                    this.setState({
                        resultsActive:"pending",
                        firstLoad:false
                    })
                }else{
                    // note - do the stat query and stuff here
                    // console.log("Map moved");
                    // console.log(view.scale)
                    statGrab('Map',timeSlider,view,this.state.mode,this.state.modeSelect)
                    .then(mapMoveStats => {
                        // console.log(mapMoveStats);
                        if(_.get(mapMoveStats,"success",false)!==false){
                            // note - update State with results
                            const mapStats=mapMoveStats.stats;
                            // note - check extent, build different symbology on the view extent
                            buildSymbology(mapStats.maxNewCases,mapStats.minNewCases,this.state.rederer)
                            this.setState({
                                resultsActive:"show",
                                viewData:{
                                    map:map,
                                    view:view,
                                    layerCases:layerCases,
                                    stateLayer:stateLayer,
                                    countyLayer:countyLayer,
                                    timeSlider:timeSlider,
                                    x:view.extent.center.x,
                                    y:view.extent.center.y
                                },
                                mapState:{
                                    dateRange:mapStats.time,
                                    totalCases:mapStats.totalCases,
                                    totalNewCases:mapStats.totalNewCases,
                                    totalDeaths:mapStats.totalDeaths,
                                    totalNewDeaths:mapStats.totalNewDeaths,
                                    totalPop:mapStats.totalPop,
                                    maxPer100kNew:mapStats.maxPer100kNew,
                                    minPer100kNew:mapStats.minPer100kNew,
                                    maxNewCases:mapStats.maxNewCases,
                                    minNewCases:mapStats.minNewCases,
                                    per100kTotalnew:mapStats.per100kTotalnew,
                                    casePercentChangeNew:mapStats.casePercentChangeNew,
                                    deathPercentNew:mapStats.deathPercentNew,
                                    deathChangePercentNew:mapStats.deathChangePercentNew,
                                    fipsArray:mapStats.fipsArray
                                }
                            })
                        }else{
                            // note - 0 results or error
                        }
                        
                    })
                    // error handle
                    
                }
                
            }

            const timeMoveHandler = () => {
                // note - the first load of stats will hit here with firstload switch hitting in mapMoveHandler
                // console.log("Time moved")
                statGrab('Time',timeSlider,view,this.state.mode,this.state.modeSelect)
                .then(timeMoveStats => {
                    // console.log(timeMoveStats)
                    if(_.get(timeMoveStats,"success",false)!==false){
                        // note - update State with results
                        const timeStats=timeMoveStats.stats;
                        // note - check extent, build different symbology on the view extent
                        buildSymbology(timeStats.maxNewCases,timeStats.minNewCases,this.state.rederer);
                        this.setState({
                            resultsActive:"show",
                            viewData:{
                                map:map,
                                view:view,
                                layerCases:layerCases,
                                stateLayer:stateLayer,
                                countyLayer:countyLayer,
                                timeSlider:timeSlider,
                                x:view.extent.center.x,
                                y:view.extent.center.y
                            },
                            mapState:{
                                dateRange:timeStats.time,
                                totalCases:timeStats.totalCases,
                                totalNewCases:timeStats.totalNewCases,
                                totalDeaths:timeStats.totalDeaths,
                                totalNewDeaths:timeStats.totalNewDeaths,
                                totalPop:timeStats.totalPop,
                                maxPer100kNew:timeStats.maxPer100kNew,
                                minPer100kNew:timeStats.minPer100kNew,
                                maxNewCases:timeStats.maxNewCases,
                                minNewCases:timeStats.minNewCases,
                                per100kTotalnew:timeStats.per100kTotalnew,
                                casePercentChangeNew:timeStats.casePercentChangeNew,
                                deathPercentNew:timeStats.deathPercentNew,
                                deathChangePercentNew:timeStats.deathChangePercentNew,
                                fipsArray:timeStats.fipsArray
                            }
                        })
                    }else{
                        // note - 0 results or error
                    }
                    
                })
            }
            
            timeWatch=timeSlider.watch("timeExtent", timeMoveHandler);
            viewHandles.push(timeWatch);
            mapWatch=watchUtils.whenTrue(view,"stationary",mapMoveHandler);
            viewHandles.push(mapWatch);
            

            if(_.get(casesView,"success",false)!==false){
                // note - map loaded clean some initial states setup
                this.setState({
                    mapLoading:false,
                    countySpinning:false,
                    stateSpinning:false,
                    viewHandles:viewHandles
                });
                if(this.state.refreshTime===true){
                    // console.log(layerCases.timeInfo)
                    const startT = moment(layerCases.timeInfo.fullTimeExtent.start).add(1, "days").format('l');
                    const endtimeT = moment(layerCases.timeInfo.fullTimeExtent.end).add(1, "days").format('l');
                    timeSlider=this.state.viewData.timeSlider;
                    timeSlider.fullTimeExtent = {
                        start: startT,
                        end: endtimeT
                    };
                    timeSlider.values = [startT, startT];
                    this.setState({
                        refreshTime:false,
                    });
                }else{
                    const start = moment(layerCases.timeInfo.fullTimeExtent.start).add(1, "days").format('l');
                    const endtime = moment(layerCases.timeInfo.fullTimeExtent.end).add(1, "days").format('l');
                    timeSlider.fullTimeExtent = {
                        start: start,
                        end: endtime
                    };
                    timeSlider.values = [start, start];
                }
            }
        }catch(err){
            // note - show error msg
            console.log(err);
        }
    }

    componentDidMount(){
        this.loadTheMap();
    }

    componentDidUpdate(prevProps,prevState){
        if(prevState.dataset!==this.state.dataset){
            // note - rerun the loadTheMap function since we changed to a different dataset
            if(this.state.refreshTime===true){
                console.log("Dataset changed:",this.state.dataset);
                // note - remove layers
                this.loadTheMap();
            }
            
        }
        if(prevState.rederer!==this.state.rederer){
            // note - rerun the loadTheMap function since we changed the renderer
            console.log("Renderer Changed:",this.state.rederer);
        }
    }

    render(){
        return(
            <>
                <Layout style={{height:"100vh",backgroundColor:"#fff"}}>
            
                    <Layout>
                    <Sider trigger={null} collapsible collapsed={this.state.collapsed} id="sidePane" width={"20%"} style={{minWidth:"150px"}} theme="dark" className="site-layout-background">
                        <Menu
                            theme="dark"
                            mode="inline"
                            defaultSelectedKeys={[this.state.activeFilter]}
                            selectedKeys={[this.state.activeFilter]}
                            defaultOpenKeys={['sub1']}
                            style={{  borderRight: 0 }}
                        >
                            <Menu.Item key="99" title="Hide">
                                {React.createElement(this.state.collapsed ? ArrowRightOutlined   : ArrowLeftOutlined, {
                                    className: 'trigger',
                                    onClick: this.toggle,
                                })}
                            </Menu.Item>
                            {/* <SubMenu
                            title=
                                {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: this.toggle,
                                })}
                            >
                                
                            </SubMenu> */}
                            
                            <SubMenu
                                onClick={(item) => this.modeSwitch(item)}
                                key="sub1"
                                title={
                                <span>
                                    <FilterOutlined />
                                    Filters
                                </span>
                                }
                            >
                                <Menu.Item key="us">United States</Menu.Item>
                                <Menu.Item key="st"><Spin spinning={this.state.stateSpinning} size="small" /> {this.state.stateSelection} </Menu.Item>
                                <Menu.Item key="co"><Spin spinning={this.state.countySpinning} size="small" /> {this.state.countySelection}</Menu.Item>
                            </SubMenu>
                            
                    </Menu>
                        <ResultsContainer
                            active={this.state.resultsActive}
                            mapState={this.state.mapState}
                            viewData={this.state.viewData}
                            mode={this.state.mode}
                            modeSelect={this.state.modeSelect}
                            dataset={this.state.dataset}
                        >
                        
                        </ResultsContainer>
                </Sider>
                
                
                <Layout>

                    <PageHeader
                        ghost={false}
                        // onBack={() => window.history.back()}
                        title="COVID-19 Cases/Deaths"
                        subTitle="Cumulative data maintained by The New York Times"
                        extra={[
                            // <DatasetModal></DatasetModal>,
                            <Dropdown overlay={(
                                <Menu>
                                    <Menu.Item key="11" onClick={this.openLex}>Smart Map</Menu.Item>
                                    <Menu.Item key="12">Legend</Menu.Item>
                                    <Menu.Item key="13">About</Menu.Item>
                                </Menu>
                            )}>
                            <Button loading={this.state.mapLoading}type="primary" key="53">
                                Actions <DownOutlined />
                            </Button>
                            </Dropdown>
                        ]}
                    >
                    <Descriptions size="small" column={4}>
                        <Descriptions.Item label="Date">{this.state.mapState.dateRange}</Descriptions.Item>
                        <Descriptions.Item label="Population">{abbreviate(this.state.mapState.totalPop,3,3,'M')}</Descriptions.Item>
                        <Descriptions.Item label="Dataset">
                            <Select loading={this.state.mapLoading} onChange={(item) => this.datasetSwitch(item,this.state.viewData)} style={{ width: 120 }} bordered={false} defaultValue="Historic">
                                <Option value="Historic">Historic</Option>
                                <Option value="LastMonth">Last Month</Option>
                            </Select>
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="Last Updated Time">07/18/2020</Descriptions.Item> */}
                        <Descriptions.Item label="Renderer">
                            <Select loading={this.state.mapLoading} onChange={(item) => this.rendererSwitch(item)} style={{ width: 140 }} bordered={false} defaultValue="Default">
                                <Option value="Default">Wurman Dots</Option>
                                <Option disabled value="WurmanCluster">Cluster/Wurman</Option>
                                <Option disabled value="Cluster">Cluster</Option>
                            </Select>
                        </Descriptions.Item>
                    </Descriptions>
                </PageHeader>
                
                    <Content
                        // className="site-layout-background"
                        style={{
                            padding: '24px 24px 24px',
                            margin: 0,
                            maxHeight: 160,
                        }}
                    >
                        
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Card size="small" loading={this.state.mapLoading} title="Cases" bordered={false} >
                                    <Row gutter={[8, 8]} justify="space-around" align="middle">
                                        <Col style={{display:"inline-block"}} span={6}>
                                            <Statistic 
                                                title="Total" 
                                                value={this.state.mapState.totalCases} 
                                                valueStyle={{ fontSize: '16px' }}
                                            />
                                        </Col>
                                        <Col style={{display:"inline-block"}} span={6}>
                                            <Statistic 
                                                // precision={2} 
                                                title="Per 100K"
                                                valueStyle={{ fontSize: '16px' }} 
                                                value={this.state.mapState.per100kTotalnew}
                                            />
                                        </Col>
                                        <Col style={{display:"inline-block"}} span={6}>
                                            <Statistic 
                                                title="New" 
                                                value={this.state.mapState.totalNewCases}
                                                valueStyle={{ fontSize: '16px' }}
                                            />
                                        </Col>
                                        <Col style={{display:"inline-block"}} span={6}>
                                            <Statistic 
                                                precision={2}
                                                title="% Change" 
                                                valueStyle={{ color: '#cf1322',fontSize: '16px' }} 
                                                value={this.state.mapState.casePercentChangeNew}
                                                suffix="%"
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                                
                            </Col>
                            <Col span={12}>
                                <Card size="small" loading={this.state.mapLoading} title="Deaths" bordered={false} >
                                    <Row gutter={[8, 8]} justify="space-around" align="middle">
                                        <Col style={{display:"inline-block"}} span={6}>
                                            <Statistic 
                                                title="Total" 
                                                value={this.state.mapState.totalDeaths}
                                                valueStyle={{ fontSize: '16px' }}
                                            />
                                        </Col>
                                        <Col style={{display:"inline-block"}} span={6}>
                                            <Statistic 
                                                precision={2} 
                                                title="% of Cases"
                                                valueStyle={{ color: '#cf1322',fontSize: '16px' }} 
                                                value={this.state.mapState.deathPercentNew}
                                                suffix="%"
                                            />
                                        </Col>
                                        <Col style={{display:"inline-block"}} span={6}>
                                            <Statistic 
                                                title="New" 
                                                value={this.state.mapState.totalNewDeaths} 
                                                valueStyle={{ fontSize: '16px' }}
                                            />
                                        </Col>
                                        <Col style={{display:"inline-block"}} span={6}>
                                            <Statistic 
                                                precision={2} 
                                                title="% Change"
                                                valueStyle={{ color: this.state.deathPColor,fontSize: '15px' }} 
                                                value={this.state.mapState.deathChangePercentNew}
                                                suffix="%"
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Content>
                    
                    <Content
                        // className="site-layout-background"
                        style={{
                            padding: '0 24px 24px',
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                    {/* <div className="site-card-wrapper"> */}
                    
                    <div id="applicationDiv" style={{position:"relative"}}>
                        <div id="viewDiv"></div>
                        <Row id="sliderContainer">
                            <div id="timeSlider"></div>
                            {/* <div id="infoDiv" class="esri-widget"></div> */}
                        </Row>
                    </div>
                    {/* </div> */}
                    </Content>
                
                </Layout>
                
                </Layout>
            
                </Layout>
            </>
        )
    }

}

export default MapAppKoopDev;