import React, { Component } from 'react';
import _ from 'lodash';
import { Interactions } from 'aws-amplify';
import { ChatFeed, Message } from 'react-chat-ui';
import config from '../../config';
import { formatState,setCountyPopUp,setStatePopUp,updateStats } from '../../Resources/functions/Functions';

const styles = {
    bubbleStyles: {
      text: {
        fontSize: 12.5,
        fontWeight:"strong",
        color:'#000000'
      },
      chatbubble: {
        borderRadius: 30,
        padding: 10,
        backgroundColor: 'rgb(255 255 255 / 81%)'
        // backgroundColor:'rgb(63, 63, 63)'
      }
    },
    headerTitle: {
      color: 'white',
      fontSize: 16
    },
    header: {
      backgroundColor: '#f0f2f5',
      padding: 5,
      paddingLeft:10,
      borderTop: '12px solid rgb(63, 63, 63)'
    },
    messagesContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: 10,
      alignItems: 'center',
      height:"40vh",
      maxHeight:"vh45",
      height:"145",
      overflow:"hidden",
      paddingLeft:"20px"
    },
    input: {
      fontSize: 12.5,
      fontWeight:"strong",
      color:"#FFFFFF",
      padding: 10,
      outline: 'none',
      width: "100%",
      border: 'none',
      borderRadius: "30px",
      backgroundColor:"rgb(0 21 41 / 81%)",
      paddingLeft:"20px"
    //   borderBottom: '2px solid rgb(63, 63, 63)'
    }
};

class TheChatBot extends Component {
    constructor(props){
        super(props);
        this.state={
            // show:this.props.show,
            theView:this.props.viewData.view,
            viewData:this.props.viewData,
            mapState:this.props.mapState,
            input: '',
            finalMessage: '',
            messages: [
                new Message({
                    id: 1,
                    message: "Hello, I can help you find state and county COVID-19 data.",
                }),
                new Message({
                    id: 2,
                    message: "Find out how a county is doing or where a state is trending.",
                }),
                new Message({
                    id: 3,
                    message: "For example, How is Anne Arundel County doing? or Where is Maryland trending?",
                })
            ]
        };
        this.onChange = this.onChange.bind(this);
        this._handleKeyPress = this._handleKeyPress.bind(this);
        this.stateQueryLex = this.stateQueryLex.bind(this);
        this.countyQueryLex = this.countyQueryLex.bind(this);
        // this.setCountyPopUp = this.setCountyPopUp.bind(this);
    }
    onChange(e) {
        const input = e.target.value
        this.setState({
          input
        })
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.submitMessage()
        }
    }

    async stateQueryLex(theState){
        // console.log("Bot Returned State");
        let query = this.props.viewData.stateLayer.createQuery();
        let fState=formatState(theState);
        let sq;
        let that=this.props.that;
        query.where="state='"+fState+"'";
        query.fields=["*"];
        query.returnGeometry=true;
        try{
            sq = await this.props.viewData.stateLayer.queryFeatures(query);
        }catch(err){
            console.log(err);
        }
        if(_.get(sq,"features.length",0)>0){
            that.setState({
                mode:"st",
                modeSelect:sq.features[0].attributes.st_abbrev,
                stateSelection:"State: "+sq.features[0].attributes.state,
                activeFilter:"st"
            });
            this.props.viewData.timeSlider.values=[this.props.viewData.timeSlider.fullTimeExtent.end];
            this.props.viewData.view.goTo(sq.features[0].geometry.extent);
            // this.props.viewData.view.popup.open({
            //     title:fState,
            //     content:await setStatePopUp("StateLex",sq.features[0].attributes.st_abbrev,this.state.mapData.timeSlider.values[0],this.state.mapData.timeSlider.values[0],sq.features[0].attributes.state_name,this.state.mapData), 
            //     // actions:[measureThisAction]
            // });
            
        }else{
            //error
        }
    }

    async countyQueryLex(theState,theCounty,view,countyLayer){
        //START HERE
        let cleanCountyName;
        if(theState && theCounty && view && countyLayer){
            let query = countyLayer.createQuery();
            let cq;
            let fState=formatState(theState);
            let fCounty=formatState(theCounty);
            _.forEach(config.layers.counties.stateLookup,function(value,key){
                if(fState===value){
                    query.where="UPPER(state)='"+key.toUpperCase()+"' AND UPPER(county)='"+fCounty.toUpperCase()+"'";
                }
            });
            query.fields=["*"]
            query.returnGeometry=true;
            try{
                cq = await countyLayer.queryFeatures(query);
            }catch(err){
                console.log(err);
            }
            if(_.get(cq,"features.length",0)>0){
                localStorage.setItem('currentState',cq.features[0].attributes.state);
                localStorage.setItem('currentCounty',cq.features[0].attributes.fips);
                view.goTo(cq.features[0].geometry.extent);
                if(cq.features[0].attributes.county.includes("City")){
                    cleanCountyName=cq.features[0].attributes.county+", "+cq.features[0].attributes.state;
                }else{
                    cleanCountyName=cq.features[0].attributes.county+" County, "+cq.features[0].attributes.state;
                }
                view.popup.open({
                    title:cleanCountyName,
                    content:await setCountyPopUp("CountyLex",cq.features[0].attributes.state,cq.features[0].attributes.fips,this.state.mapData.timeSlider.values[0],this.state.mapData.timeSlider.values[0],cq.features[0].attributes.county,this.state.mapData),
                });
            }
        }
    }

    async submitMessage(theState,theCounty,view) {
        

        const { input } = this.state
        if (input === '') return
        const message = new Message({
            id: 0,
            message: input,
        })
        let messages = [...this.state.messages, message]
        
        this.setState({
            messages,
            input: ''
        })
        const response = await Interactions.send("covidAskData", input);
        const responseMessage = new Message({
            id: 1,
            message: response.message,
        })
        messages  = [...this.state.messages, responseMessage]
        this.setState({ messages })
        
        if(response.dialogState === 'Fulfilled') {
            if (response.intentName === 'stateTrend') {
                console.log(this.props)
                
                await this.stateQueryLex(response.slots.state);
            }
            if (response.intentName === 'StateAbbrev') {
                await this.stateQueryLex(response.slots.state);
            }
            if (response.intentName === 'countyLookup') {
                await this.countyQueryLex(response.slots.stateCounty,response.slots.county,this.state.theView,this.state.countyLayer);
            }
        }
    }

    render() {
        return (
            <div className="App" style={{backgroundColor:"#d1d1d100",height:"0",maxWidth:"400px",width:"400px"}}>
                <div style={styles.messagesContainer}> 
                    <div id="chatContainer" style={{paddingBottom:"20"}}>
                        <ChatFeed
                            style={{maxHeight:"450px",overflowY:"scroll",paddingRight:"0px"}}
                            messages={this.state.messages}
                            hasInputField={false}
                            bubbleStyles={styles.bubbleStyles}
                        />
                    </div>
                    <input
                        onKeyPress={this._handleKeyPress}
                        onChange={this.onChange.bind(this)}
                        style={styles.input}
                        value={this.state.input}
                    />
                </div> 
            </div>
        );
    }    
}

export default TheChatBot;