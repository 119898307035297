import React, { Component } from 'react';
import { Spin } from 'antd';
import _ from 'lodash';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { deathCount,newDeathCount } from '../../../Resources/functions/Functions';

class ApexChartDeaths extends Component {
    constructor(props) {
      super(props);

      this.state = {
            active:false,
            loading:true,
            theData:false,
            series: [],
            options: {
                chart: {
                    type: 'area',
                    height: "100%",
                    stacked: false,
                    foreColor: '#FFF',
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 400,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 50
                        }
                    }
                },
                colors: ['#FF0000', '#31778E'],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        barHeight: '80%',
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 1,
                    colors: ['#CF4917', '#31778E']
                },
                
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                yaxis: [
                    {
                        seriesName: 'Deaths',
                        show:false,
                        opposite: false,
                        axisTicks: {
                          show: true,
                        },
                        axisBorder: {
                          show: false,
                        //   color: '#00E396'
                        },
                        labels: {
                            show:false,
                            style: {
                                colors: '#00E396',
                            }
                        },
                        title: {
                          text: "Deaths",
                        //   style: {
                        //     color: '#00E396',
                        //   }
                        },
                    },
                    {
                        seriesName: 'New Deaths',
                        show:false,
                        opposite: true,
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: false,
                        //   color: '#00E396'
                        },
                        labels: {
                            show:false,
                            style: {
                                colors: '#00E396',
                            }
                        },
                        title: {
                            text: "New Deaths",
                        //   style: {
                        //     color: '#00E396',
                        //   }
                        },
                    },
                ],
                tooltip: {
                    shared: true,
                    theme: "dark",
                    // x: {
                    //     formatter: function (val) {
                    //         return val
                    //     }
                    // },
                    y: {
                        formatter: function (val) {
                            return Math.abs(val);
                        }
                    }
                },
                // title: {
                //     text: 'This will be a chart'
                // },
                xaxis: {
                    type: 'datetime',
                    categories: [],
                    labels: {
                        show:true,
                        datetimeFormatter: {
                            year: 'yyyy',
                            month: 'MMM dd',
                            day: 'MMM dd',
                        }
                    }
                },
            },
      };
    }

    async updateChart(){
        var dateData=[];
        var deathData=[];
        var deathNewData=[];
        var mapData = this.props.theProps.viewData;
        let inQ="";

        if(_.get(mapData.timeSlider.timeExtent,"end",false)!==false){
            _.forEach(this.props.theProps.mapState.fipsArray,function(value,key){
                inQ+="'"+value+"',";
            });
            inQ=inQ.slice(0, -1); 
            var statQuery = mapData.layerCases.createQuery();
            if(this.props.theProps.mode==="us"){
                statQuery.where="flag=1 AND startdate_converted<=date'" + moment(mapData.timeSlider.timeExtent.start.getTime()).format('l')+"' AND fipsgj IN("+inQ+")";
            }else if(this.props.theProps.mode==="st"){
                statQuery.where="flag=1 AND startdate_converted<=date'" + moment(mapData.timeSlider.timeExtent.start.getTime()).format('l')+"' AND fipsgj IN("+inQ+")";
            }else if(this.props.theProps.mode==="co"){
                statQuery.where="flag=1 AND startdate_converted<=date'" + moment(mapData.timeSlider.timeExtent.start.getTime()).format('l')+"' AND fipsgj IN("+inQ+")";
            }else{

            }
            statQuery.outStatistics = [
                deathCount,
                newDeathCount,
            ];
            statQuery.orderByFields=["date"];
            statQuery.groupByFieldsForStatistics=["date"];
            statQuery.returnGeometry=false;
            var sq = await mapData.layerCases.queryFeatures(statQuery);
            
            if(_.get(sq,"features.length",0)>0){
                _.forEach(sq.features,function(value,key){
                    dateData.push((new Date(value.attributes.date)).getTime());
                    deathData.push(value.attributes.deathCount);
                    deathNewData.push(value.attributes.newDeathCount);
                });

                
                this.setState({
                    series: [
                        {
                            name: 'Deaths',
                            type: 'area',
                            data: deathData
                        },
                        {
                            name: 'New Deaths',
                            type:'bar',
                            data:deathNewData
                        }
                        
                    ],
                    options: {
                    ...this.state.options,
                    
                    xaxis: {
                        ...this.state.options.xaxis,
                        categories:dateData
                    }
                    },
                    loading:false,
                    error:false,
                    active:true,
                    theData:true,
                    details:{

                    }
                });
            }else{
                // no data or error
                console.log("No data");
                this.setState({
                    active:false,
                    loading:false,
                    theData:false
                });
            }

        }else{
            this.setState({
                active:false,
                loading:false,
                theData:false
            });

        }
    }

    componentDidMount(){
        console.log("Death Chart: Mounted");
        this.updateChart();
        // if(this.props.active===true && _.isEmpty(this.props.mapData)===false && _.isEmpty(this.props.moveEnd)===false){
        //     this.updateChart();
        // }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.theProps.mapState.dateRange!==this.props.theProps.mapState.dateRange){
            console.log("Death Chart: Time Updated");
            this.updateChart();
        }
        if(this.props.theProps.viewData.x!==prevProps.theProps.viewData.x || this.props.theProps.viewData.x!==prevProps.theProps.viewData.x){
            console.log("Death Chart: Map Moved x,y");
            this.updateChart();
        }
    }


    render() {

        if(this.state.active===true && this.state.loading===false && this.state.theData===true){
            return (
                <>
                    <div id="chart" className="resultPane">
                        <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={"100%"} />
                    </div>
                </>
            );
        }else if(this.state.active===true && this.state.loading===true && this.state.theData===false){
            return (
                <>
                    <Spin size="large" />
                </>
            )
        }else if(this.state.active===true && this.state.loading===false && this.state.theData===false){
            return (
                <>
                    <div>
                        <p>No Data</p>
                    </div>
                </>
            );

        }else if(this.state.active===false && this.state.loading===true){
            return(
                <>
                    <div className="loadCenter">
                        <Spin />
                    </div>
                </>

            )
        }else{
            return (
                <>
                    <div>
                        <p>Loading...</p>
                    </div>
                </>
            );
        }
    }
}

export default ApexChartDeaths;