// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
    'aws_app_analytics': 'enable',
    'aws_bots': 'enable',
    'aws_bots_config': [{"name":"covidAskData","alias":"$LATEST","description":"","bot-template":"bot-import","commands-help":[],"region":"us-east-1"}],
    'aws_cognito_identity_pool_id': 'us-east-1:3f8b6ede-25a1-4c9c-9997-2ffdda1b6c1e',
    'aws_cognito_region': 'us-east-1',
    'aws_content_delivery': 'enable',
    'aws_content_delivery_bucket': 'giscovidtime-hosting-mobilehub-286759163',
    'aws_content_delivery_bucket_region': 'us-east-1',
    'aws_content_delivery_cloudfront': 'enable',
    'aws_content_delivery_cloudfront_domain': 'd2nm3j6ns5wzud.cloudfront.net',
    'aws_mobile_analytics_app_id': '5ef28a44f80d428694c087fea198ae74',
    'aws_mobile_analytics_app_region': 'us-east-1',
    'aws_project_id': '06339e12-f0bd-41c1-a1d8-9bc86d3d972d',
    'aws_project_name': 'gis-covid19-time-2020-04-09-02-05-45',
    'aws_project_region': 'us-east-1',
    'aws_resource_name_prefix': 'giscovidtime-mobilehub-286759163',
    'aws_sign_in_enabled': 'enable',
    'aws_user_pools': 'enable',
    'aws_user_pools_id': 'us-east-1_NXI8BbiAp',
    'aws_user_pools_mfa_type': 'ON',
    'aws_user_pools_web_client_id': '7tr62aick2jk9jpf0acgqv306s',
}

export default awsmobile;
