import React, { Component } from 'react';
import { Row, Col,Typography,Spin,Tabs  } from 'antd';
import { updateStats } from '../../Resources/functions/Functions';
import _ from 'lodash';
import ApexChartChange from '../Charts/Components/ChangeChart';
import ApexChartDeaths from '../Charts/Components/DeathChart';
import ApexChartCases from '../Charts/Components/CaseChart';
import DetailList from '../Charts/Components/DetailsList';

const { Title,Text } = Typography;
const { TabPane } = Tabs;

class ResultsContainer extends Component{
    constructor(props){
        super(props)
        this.state={
            active:this.props.active,
            mode:this.props.mode,
            data:this.props.data,
            mapData:this.props.mapData,
            title:"",
            that:this.props.that
        };

        // this.updateStats = this.updateStats.bind(this);
    }

    async buildResults(){
        if(this.props.active!=="Off"){
            let timeSlider=this.props.mapData.timeSlider;
            
            if(this.props.mode==="State"){
                console.log("Results - State")
                this.setState({
                    title:this.props.data.title,
                    active:this.props.active
                });
                await updateStats(timeSlider.timeExtent.start,timeSlider.timeExtent.end,this.props.data.mode,this.props.data.attributes.st_abbrev,'',this.props.mapData,this.props.that);

            }else if(this.props.mode==="County"){
                this.setState({
                    title:this.props.data.title,
                    active:this.props.active
                });

            }else if(this.props.mode==="Country"){
                console.log("Results - Country")

            }else{

            }
            
        }
    }

    updateResults(data){
        this.buildResults();
    }
    
    hideResults(active){
        this.setState({active:active})
    }

    componentDidUpdate(prevProps) {
        if(this.props.active==="show"){
            if(this.props.mode==="us"){
                // note - do a normal stat update
                console.log("Results Updated:",this.props);
                // console.log(this.props);
            }
            if(this.props.mode==="st"){
                // note - make sure modeSelected is not null
                console.log("Results Updated:",this.props);
                console.log(this.props);
            }
            if(this.props.mode==="co"){
                // note - make sure modeSelected is not null
                console.log("Results Updated:",this.props);
                console.log(this.props);
            }
        }
    }

    componentWillUnmount(){
        this.setState({
            active:"hide"
        });
    }

    render(){
        // console.log(this.state)
        if(this.props.active==="hide"){
            return(
                <></>
            )

        }else if(this.props.active==="pending"){
            return(
                <>
                    <div className="loadCenter">
                        <Spin />
                    </div>
                </>

            )

        }else if(this.props.active==="show"){
            return(
                <>
                    <Row style={{
                        height:"100%"
                    }}>
                        <Col span={24} style={{
                            paddingLeft:"12px",
                            paddingRight:"12px"
                        }}>
                            
                            <Tabs defaultActiveKey="1" animation={false}>
                            
                                <TabPane tab="Details" key="1" style={{color:"white"}}>
                                    <Row>
                                        <Col style={{width:"100%"}} className="shapes-list">
                                            <DetailList theProps={this.props} />
                                        </Col>
                                    </Row>
                                </TabPane>
                                
                                <TabPane tab="Cases" key="2" style={{color:"white"}}>
                                    <Row>
                                        <Col style={{width:"100%"}}>
                                            <ApexChartCases theProps={this.props} />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab="Deaths" key="3" style={{color:"white"}}>
                                    <Row>
                                        <Col style={{width:"100%"}}>
                                            <ApexChartDeaths theProps={this.props} />
                                        </Col>
                                    </Row>

                                </TabPane>
                                <TabPane tab="Change" key="4" style={{color:"white"}}>
                                    <Row>
                                        <Col style={{width:"100%"}}>
                                            <ApexChartChange theProps={this.props} />
                                        </Col>
                                    </Row>

                                </TabPane>
                            </Tabs>
                            
                        </Col>
                    </Row>`
                </>
            )

        }else{
            return(
                <>
                </>

            )
        }
        
    }

    

}

export default ResultsContainer;